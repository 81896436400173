<template>
  <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white mt-5">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <div class="d-flex flex-column px-4">
      <img
        :src="dummyAvatar"
        class="rounded-circle"
        width="100"
        height="100"
        alt=""
      />
      <span class="font-tiny grey-accent mt-1"
        ><i class="bi bi-pen-fill pb-1"></i> Select Photo</span
      >
    </div>
    <form @submit.prevent="createNewSchool">
      <div class="row justify-content-between px-2 pb-4">
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> School Code </label>
          <input
            type="text"
            placeholder="Enter school code"
            v-model="schoolInfo.schoolCode"
            :disabled="edit"
            v-bind:class="{
              'form-control': true,
              'is-invalid':
                !validSchoolCode(schoolInfo.schoolCode) && schoolCodeBlured,
            }"
            v-on:blur="schoolCodeBlured = true"
          />
          <div class="invalid-feedback">A Valid School code is required!</div>
        </div>
        <div class="col-4 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            School coordinates
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Elevation"
            v-model="schoolInfo.elevation"
          />
        </div>
        <div class="col-4 mt-5 px-3">
          <input
            type="text"
            class="form-control"
            placeholder="Longitude"
            v-model="schoolInfo.longitude"
          />
        </div>
        <div class="col-4 mt-5 px-3">
          <input
            type="text"
            class="form-control"
            placeholder="Latitude"
            v-model="schoolInfo.latitude"
          />
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> School Name </label>
          <input
            type="text"
            placeholder="Enter school name"
            v-model="schoolInfo.schoolName"
            :disabled="edit"
            v-bind:class="{
              'form-control': true,
              'is-invalid':
                !validString(schoolInfo.schoolName) && schoolNameBlured,
            }"
            v-on:blur="schoolNameBlured = true"
          />
          <div class="invalid-feedback">School Name is required!</div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Level(s) of education offered
          </label>
          <div class="d-flex">
            <div class="col px-1">
              <div class="form-check">
                <input
                  v-bind:class="{
                    'form-check-input': true,
                    'is-invalid':
                      !validString(schoolInfo.levelsOffered) &&
                      levelsOfferedBlured,
                  }"
                  type="checkbox"
                  v-model="schoolInfo.levelsOffered"
                  value="PRE_PRIMARY"
                  id="pre-primary"
                  :checked="schoolInfo.levelsOffered['PRE_PRIMARY']"
                />
                <label class="form-check-label" for="pre-primary">
                  Pre-Primary
                </label>
              </div>
            </div>
            <div class="col px-1">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="schoolInfo.levelsOffered"
                  value="PRIMARY"
                  id="primary"
                  :checked="schoolInfo.levelsOffered['PRIMARY']"
                />
                <label class="form-check-label" for="primary"> Primary </label>
              </div>
            </div>
            <div class="col px-1">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="schoolInfo.levelsOffered"
                  value="JUNIOR"
                  id="junior-secondary"
                  :checked="schoolInfo.levelsOffered['JUNIOR']"
                />
                <label class="form-check-label" for="junior-secondary">
                  Junior secondary
                </label>
              </div>
            </div>
            <div class="col px-1">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="schoolInfo.levelsOffered"
                  value="SENIOR"
                  id="senior-secondary"
                  :checked="schoolInfo.levelsOffered['SENIOR']"
                />
                <label class="form-check-label" for="senior-secondary">
                  Senior secondary
                </label>
              </div>
            </div>
            <div class="invalid-feedback">Level Offered is required!</div>
          </div>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Street Name </label>
          <input
            type="text"
            placeholder="Street name"
            v-model="schoolInfo.streetName"
            v-bind:class="{
              'form-control': true,
              'is-invalid':
                !validString(schoolInfo.streetName) && streetNameBlured,
            }"
            v-on:blur="streetNameBlured = true"
          />
          <div class="invalid-feedback">Street Name is required!</div>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Street Number </label>
          <input
            type="number"
            class="form-control"
            placeholder="Street number"
            v-model="schoolInfo.streetNumber"
            v-bind:class="{
              'form-control': true,
              'is-invalid':
                !streetNumberValidation(schoolInfo.streetNumber) &&
                streetNumberBlured,
            }"
            v-on:blur="streetNumberBlured = true"
          />
          <div class="invalid-feedback">Street number is required!</div>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Name of village/town
          </label>
          <input
            type="text"
            placeholder="Village or town"
            v-model="schoolInfo.villageName"
            v-bind:class="{
              'form-control': true,
              'is-invalid':
                !validString(schoolInfo.villageName) && streetNameBlured,
            }"
            v-on:blur="villageNameBlured = true"
          />
          <div class="invalid-feedback">Village Name is required!</div>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            School classification
          </label>
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="schoolInfo.classification"
            v-bind:class="{
              'form-control': true,
              'is-invalid':
                !validString(schoolInfo.classification) &&
                schoolClassificationBlured,
            }"
            v-on:onChange="schoolClassificationBlured = true"
          >
            <option
              v-if="schoolInfo.classification"
              :value="schoolInfo.classification"
              selected
            >
              {{ schoolInfo.classification }}
            </option>
            <option v-else value="">School classification</option>
            <option value="Public">Public</option>
            <option value="Private">Private</option>
          </select>
          <div class="invalid-feedback">
            School Classifications is required!
          </div>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> School type </label>
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="schoolInfo.schoolType"
            v-bind:class="{
              'form-control': true,
              'is-invalid':
                !validString(schoolInfo.schoolType) && schooltypeBlured,
            }"
            v-on:onChange="schooltypeBlured = true"
          >
            <option
              v-if="schoolInfo.schoolType"
              :value="schoolInfo.schoolType"
              selected
            >
              {{ schoolInfo.schoolType }}
            </option>
            <option v-else value="">School category</option>
            <option value="regular">Regular</option>
            <option value="islamiyya">Islamiyya</option>
            <option value="nomadic">Normadic</option>
            <option value="special">Special needs</option>
            <option value="science_technology">Science and technology</option>
            <option value="vocation_training">Vocational training</option>
            <option value="science_technology_vocational">
              Science, technology and vocational
            </option>
          </select>
          <div class="invalid-feedback">School Type is required!</div>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Location </label>
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="schoolInfo.location"
            v-bind:class="{
              'form-control': true,
              'is-invalid':
                !validString(schoolInfo.location) && schoolLocationBlured,
            }"
            v-on:onChange="schoolLocationBlured = true"
          >
            <option
              v-if="schoolInfo.location"
              :value="schoolInfo.location"
              selected
            >
              {{ schoolInfo.location }}
            </option>
            <option v-else value="">School location</option>
            <option value="rural">Rural</option>
            <option value="urban">Urban</option>
          </select>
          <div class="invalid-feedback">School Location is required!</div>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Local government area
          </label>
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="schoolInfo.lga"
            v-bind:class="{
              'form-control': true,
              'is-invalid': !validString(schoolInfo.lga) && schoolLgaBlured,
            }"
            @change="getWard(schoolInfo.lga), (schoolLgaBlured = true)"
          >
            <option v-if="schoolInfo.lga" :value="schoolInfo.lga" selected>
              {{ schoolInfo.lga }}
            </option>
            <option v-else value="">Select LGA</option>
            <option v-for="(lg, index) in lgs" :key="index" :value="lg.name">
              {{ lg.name }}
            </option>
          </select>
          <div class="invalid-feedback">School Lga is required !</div>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Ward </label>
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="schoolInfo.ward"
            v-bind:class="{
              'form-control': true,
              'is-invalid': !validString(schoolInfo.ward) && schoolWardBlured,
            }"
            @change="schoolWardBlured = true"
          >
            <option v-if="schoolInfo.ward" :value="schoolInfo.ward" selected>
              {{ schoolInfo.ward }}
            </option>
            <option v-else value="">Select Ward</option>
            <option v-for="(ward, index) in wards" :key="index" :value="ward">
              {{ ward }}
            </option>
          </select>

          <div class="invalid-feedback">Ward is required!</div>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> School telephone </label>
          <input
            type="number"
            placeholder="+234"
            v-model="schoolInfo.telephone"
            v-bind:class="{
              'form-control': true,
              'is-invalid':
                !streetNumberValidation(schoolInfo.telephone) &&
                schoolTelBlured,
            }"
            v-on:blur="schoolTelBlured = true"
          />
          <div class="invalid-feedback">School Telephone is required!</div>
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            School email address
          </label>
          <input
            placeholder="Enter school email"
            v-model="schoolInfo.email"
            :disabled="edit"
            v-bind:class="{
              'form-control': true,
              'is-invalid': !validEmail(schoolInfo.email) && emailBlured,
            }"
            v-on:blur="emailBlured = true"
          />
          <div class="invalid-feedback">A valid email is required!</div>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <button v-if="edit" class="custom-btn font-tiny fw-bold">
          Edit school
        </button>
        <!--        @click="createNewSchool"-->
        <button v-else class="custom-btn font-tiny fw-bold">
          Create school
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import lodash from "lodash";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import avatar from "@/assets/images/emptyAvatar.png";
import stateNLG from "@/helpers/utils/state_local.json";
import wardData from "@/helpers/utils/wards.json";
import { mapActions, mapMutations } from "vuex";
import { toRef } from "@vue/runtime-core";
import { emisconfig } from "../../../../../app.config";
export default {
  name: "AddSchoolInformation",
  props: ["edit", "editSchool"],
  components: {
    ClipLoader,
  },
  // setup(props) {
  //   const operation = props.edit ? "Edit School" : "Create School";
  //   const selectedSchool = toRef(props, 'editSchool')

  //   let schoolId = null

  //   let id = "", schoolCode = "", elevation = "", schoolName = "", lga = "", ward = "", streetNumber = "", schoolType = ""
  //   let streetName = "", email = "", location = "", schoolTelephone = "", villageOrTown = "", longitude = "", latitude = ""
  //   let classification = ""

  // if (selectedSchool.value !== null && operation === "Edit School") {
  //   ({
  //       id, schoolCode, elevation, schoolName, lga, ward, streetName, streetNumber, email,
  //       schoolTelephone, villageOrTown, longitude, latitude, location, schoolType, classification
  //     } = selectedSchool.value
  //   )
  //   schoolId = id
  // }

  //   const schoolInfo = {
  //     schoolCode: schoolCode,
  //     elevation: elevation,
  //     schoolName: schoolName,
  //     streetName: streetName,
  //     streetNumber: streetNumber,
  //     lga: lga,
  //     ward: ward,
  //     state: state,
  //     email: email,
  //     telephone: schoolTelephone,
  //     villageName: villageOrTown,
  //     longitude: longitude,
  //     latitude: latitude,
  //     location: location,
  //     classification: classification,
  //     schoolType: schoolType,
  //   };
  //   return {
  //     operation,
  //     schoolId: schoolId,
  //     lgs,
  //     schoolInfo,
  //     dummyAvatar,
  //     lodash
  //   };
  // },
  data() {
    return {
      schoolId: "",
      operation: "",
      selectedSchool: {},
      wards: "",
      dummyAvatar: avatar,
      state: Object.entries(stateNLG)[emisconfig.stateCode],
      lgs: "",
      schoolInfo: {
        schoolCode: "",
        elevation: "",
        schoolName: "",
        streetName: "",
        streetNumber: "",
        lga: "",
        ward: "",
        state: "",
        email: "",
        telephone: "",
        villageName: "",
        longitude: "",
        latitude: "",
        location: "",
        classification: "",
        schoolType: "",
        levelsOffered: [],
      },
      codeSchool: "",
      valid: false,
      schoolCodeBlured: false,
      schoolNameBlured: false,
      levelsOfferedBlured: false,
      streetNameBlured: false,
      streetNumberBlured: false,
      villageNameBlured: false,
      schoolClassificationBlured: false,
      schooltypeBlured: false,
      schoolLocationBlured: false,
      schoolLgaBlured: false,
      schoolWardBlured: false,
      schoolTelBlured: false,
      emailBlured: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.school.loading;
    },
    message() {
      return this.$store.state.school.schoolMessage;
    },
    responseCode() {
      return this.$store.state.school.resCode;
    },
  },
  beforeMount() {
    this.lgs = Object.values(this.state[1].locals);
    this.operation = this.edit ? "Edit School" : "Create School";
    this.selectedSchool = this.editSchool;

    if (this.edit && this.editSchool) {
      console.log(this.editSchool);
      console.log(this.edit);
      this.schoolInfo.schoolCode = this.editSchool.schoolCode;
      this.schoolInfo.elevation = this.editSchool.elevation;
      this.schoolInfo.schoolName = this.editSchool.schoolName;
      this.schoolInfo.streetName = this.editSchool.streetName;
      this.schoolInfo.streetNumber = this.editSchool.streetNumber;
      this.schoolInfo.lga = this.editSchool.lga;
      this.schoolInfo.ward = this.editSchool.ward;
      this.schoolInfo.state = this.editSchool.state;
      this.schoolInfo.email = this.editSchool.email;
      this.schoolInfo.telephone = this.editSchool.schoolTelephone;
      this.schoolInfo.villageName = this.editSchool.villageOrTown;
      this.schoolInfo.longitude = this.editSchool.longitude;
      this.schoolInfo.latitude = this.editSchool.latitude;
      this.schoolInfo.location = this.editSchool.location;
      this.schoolInfo.classification = this.editSchool.classification;
      this.schoolInfo.schoolType = this.editSchool.schoolType;
      this.schoolInfo.levelsOffered = this.editSchool.levelsOffered;
      this.schoolId = this.editSchool.id;
    } else {
    }
    //  console.log(this.state);
  },
  methods: {
    ...mapActions(["createSchool", "updateSchoolInfo"]),
    ...mapMutations(["setSchoolMessage"]),
    getWard(lg) {
      this.schoolInfo.ward = "";
      this.wards = "";
      let lgWards;
      lgWards = wardData.filter((lga) => lga.name == lg);
      this.wards = lgWards[0].wards;
    },
    validate() {
      this.schoolCodeBlured = true;
      this.schoolNameBlured = true;
      this.levelsOfferedBlured = true;
      this.streetNameBlured = true;
      this.streetNumberBlured = true;
      this.villageNameBlured = true;
      this.schooltypeBlured = true;
      this.schoolLocationBlured = true;
      this.schoolClassificationBlured = true;
      this.schoolLgaBlured = true;
      this.schoolWardBlured = true;
      this.schoolTelBlured = true;
      this.emailBlured = true;
      if (
        this.validSchoolCode(this.schoolInfo.schoolCode) &&
        this.validString(this.schoolInfo.schoolName) &&
        this.validString(this.schoolInfo.levelsOffered) &&
        this.validString(this.schoolInfo.streetName) &&
        this.streetNumberValidation(this.schoolInfo.streetNumber) &&
        this.validString(this.schoolInfo.villageName) &&
        this.validString(this.schoolInfo.classification) &&
        this.validString(this.schoolInfo.schoolType) &&
        this.validString(this.schoolInfo.location) &&
        this.validString(this.schoolInfo.lga) &&
        this.validString(this.schoolInfo.ward) &&
        this.streetNumberValidation(this.schoolInfo.telephone) &&
        this.validEmail(this.schoolInfo.email)
      ) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    validSchoolCode(schoolCode) {
      if (schoolCode.length > 0) {
        return true;
      }
    },
    validString(string) {
      if (string !== undefined && string !== null && string.length > 0) {
        return true;
      }
    },
    validEmail(email) {
      var re = /(.+)@(.+){2,}\.(.+){2,}/;
      if (re.test(email.toLowerCase())) {
        return true;
      }
    },
    streetNumberValidation(streetNumber) {
      if (streetNumber > 0) {
        return true;
      }
    },
    async createNewSchool() {
      this.validate();
      if (this.valid) {
        const schoolData = {
          schoolCode: this.schoolInfo.schoolCode,
          elevation: `${this.schoolInfo.elevation}cm`,
          longitude: this.schoolInfo.longitude,
          latitude: this.schoolInfo.latitude,
          schoolName: this.schoolInfo.schoolName,
          streetName: this.schoolInfo.streetName,
          streetNumber: this.schoolInfo.streetNumber,
          villageOrTown: this.schoolInfo.villageName,
          lga: this.schoolInfo.lga.toUpperCase(),
          ward: this.schoolInfo.ward,
          location: this.schoolInfo.location.toUpperCase(),
          classification: this.schoolInfo.classification.toUpperCase(),
          schoolType: this.schoolInfo.schoolType.toUpperCase(),
          schoolTelephone: this.schoolInfo.telephone,
          email: this.schoolInfo.email,
        };
        const organizationData = {
          name: this.schoolInfo.schoolName,
          organizationCode: "EDUCATIONAL",
        };

        if (this.operation === "Edit School") {
          const requestObject = {
            school: schoolData,
            organization: organizationData,
          };
          const body = {
            id: this.schoolId,
            body: requestObject,
          };
          await this.updateSchoolInfo(body).then(() => {
            if (this.message !== null) {
              console.log("Update School");
              // this.showModal = true
            }
          });
        } else {
          schoolData.levelsOffered = this.schoolInfo.levelsOffered;
          const requestObject = {
            school: schoolData,
            organization: organizationData,
          };
          await this.createSchool(requestObject)
            .then(() => {
              if (this.responseCode === 201) {
                this.$notification.open({
                  type: "success",
                  message: "Success",
                  description: "School created successfully",
                });
                this.clearFields();
              }
              let status = parseInt(
                this.responseCode.toString().match(/\d+/)[0]
              );
              if (status == 409) {
                this.$notification.open({
                  type: "error",
                  message: "Duplicate Error",
                  description: "School Code Already Exists!",
                });
              } else if (status == 404) {
                this.$notification.open({
                  type: "error",
                  message: "Network Error",
                  description: "Something went wrong with the network",
                });
              }
            })
            .catch((err) => console.log(err, "************"));
        }
      } else {
        //  console.log('Validation erro');
      }
    },
  },

  clearFields() {
    this.schoolInfo.schoolCode = "";
    this.schoolInfo.elevation = "";
    this.schoolInfo.schoolName = "";
    this.schoolInfo.streetNumber = "";
    this.schoolInfo.lga = "";
    this.schoolInfo.ward = "";
    this.schoolInfo.state = "";
    this.schoolInfo.streetName = "";
    this.schoolInfo.email = "";
    this.schoolInfo.telephone = "";
    this.schoolInfo.villageName = "";
    this.schoolInfo.levels = [];
    this.schoolInfo.longitude = "";
    this.schoolInfo.latitude = "";
    this.schoolInfo.location = "";
    this.schoolInfo.classification = "";
    this.schoolInfo.schoolType = "";
  },
};
</script>
