<template>
  <div class="d-flex flex-column">
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <div v-if="showTitle" class="d-flex justify-content-between align-items-center my-4">
      <span class="font-normal fw-bold">School staff table</span>
    </div>

    <div class="d-flex radius-half bg-white shadow-sm p-3 mb-3">
      <div class="col-6 d-flex flex-column pe-3">
        <span class="font-tiny fw-bold mb-2">What are you looking for?</span>
        <div class="custom-input-group bg-accent">
          <span class="p-1">
            <span class="material-icons font-normal pt-1 grey-shade">
              search
            </span>
          </span>
          <input
            type="text"
            class="ph-sm no-outline-input font-tiny fw-bolder"
            placeholder="Search"
            v-model="searchField"
          />
        </div>
      </div>
      <div class="col-3 d-flex flex-column pe-2">
        <span class="font-tiny fw-bold mb-2">Filter</span>
        <div>
          <select
            class="form-select text-dark"
            aria-label="Default select example"
            v-model="searchFilter"
          >
            <option class="bg-white grey-shade" selected>All</option>
            <option class="bg-white" value="name">Name</option>
            <option class="bg-white" value="gender">Gender</option>
            <option class="bg-white" value="staffType">staffType</option>
            <option class="bg-white" value="teachingType">teachingType</option>
            <option class="bg-white" value="gradeLevel">gradeLevel</option>
            <option class="bg-white" value="sfn">Staff file number</option>
          </select>
        </div>
      </div>
      <div class="col-3 d-flex flex-column ps-2">
        <span class="font-tiny fw-bold mb-2">Sort</span>
        <div>
          <select
            class="form-select text-dark"
            aria-label="Default select example"
            v-model="sortOption"
          >
            <option class="bg-white grey-shade" selected>All</option>
            <option class="bg-white" value="name">Name</option>
            <option class="bg-white" value="gender">Gender</option>
            <option class="bg-white" value="staffType">staffType</option>
            <option class="bg-white" value="teachingType">teachingType</option>
            <option class="bg-white" value="gradeLevel">gradeLevel</option>
            <option class="bg-white" value="sfn">Staff file number</option>
          </select>
        </div>
      </div>
    </div>

    <div class="table-responsive bg-white shadow-sm radius-full mb-5">
      <table class="table table-hover font-sm" aria-label="Users table">
        <thead>
          <tr>
            <th class="py-3 ps-4 big-cell first" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Full Name</span>
                <div class="vertical-icons ms-2">
                  <span 
                    @click="nameArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    @click="nameArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="py-3 small-cell" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Gender</span>
                <div class="vertical-icons ms-1">
                  <span 
                    @click="genderArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    @click="genderArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="py-3 medium-cell" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Staff file number</span>
                <div class="vertical-icons ms-1">
                  <span 
                    @click="sfnArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    @click="sfnArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="py-3 normal-cell" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Staff Type</span>
                <div class="vertical-icons ms-1">
                  <span 
                    @click="staffTypeArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    @click="staffTypeArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="py-3 small-cell" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Personnel</span>
                <div class="vertical-icons ms-1">
                  <span 
                    @click="teachingTypeArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    @click="teachingTypeArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="py-3 normal-cell" scope="col">
              <div class="d-flex flex-nowrap align-items-center">
                <span>Grade Level</span>
                <div class="vertical-icons ms-1">
                  <span 
                    @click="gradeLevelArrowUpClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_up
                  </span>
                  <span 
                    @click="gradeLevelArrowDownClicked"
                    class="material-icons material-icon-spacing font-medium pointer-no-bg">
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </th>
            <th class="py-3 smallest-cell" scope="col"></th>
          </tr>
        </thead>
        <tbody v-if="filteredUsers.length > 0">
          <tr
            class="pointer-no-bg"
            v-for="(user, index) in filteredUsers"
            :key="index"
            @click="staffProfile(user)"
          >
            <td class="py-3 ps-4 first">
              {{ user.firstName + " " + user.lastName }}
            </td>
            <td class="py-3">{{ user.gender }}</td>
            <td class="py-3">{{ user.staffFileNo }}</td>
            <td class="py-3">{{ lodash.capitalize(user.typeOfStaff) }}</td>
            <td class="py-3">{{ lodash.capitalize(user.teachingType).replace("_", " ") }}</td>
            <td class="py-3">{{ lodash.capitalize(user.gradeLevel[0]).replace("_", " ") }}</td>
            <td class="text-end dropend pe-2 py-3">
              <span @click.stop class="p-3">
                <span 
                  class="material-icons-outlined grey-accent px-3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  more_vert
                </span>
                <ul
                  class="
                    dropdown-menu
                    action-menu
                    bg-accent
                    shadow
                    font-tiny
                    radius-half
                  "
                  aria-labelledby="options"
                >
                  <li @click="staffProfile(user)">
                    <span class="dropdown-item grey-accent">                    
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        person
                      </span>
                      Profile
                    </span>
                  </li>
                  <li @click="editStaff(user)">
                    <span class="dropdown-item grey-accent" href="#">
                      <span class="material-icons-outlined fw-medium font-md me-2">
                        edit
                      </span>
                      Edit
                    </span>
                  </li>
                </ul>
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8" class="text-center fw-medium">
              {{ message }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end align-items-center font-sm pb-3 px-4">
        <span>Rows per page:
          <select v-model="numberOfRows" v-on:change="onRowsChanged($event)" class="outline-clear" id="row">
            <option value="5">5</option>
            <option value="12">12</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>

        <span class="font-sm ms-3">1 to {{numberOfRows}} of {{totalRecord}}
          <span
            @click="fetchPrev"
            class="material-icons-outlined font-tiny pointer-no-bg ms-2"
          >
            arrow_back_ios
          </span>
          <span
            @click="fetchNext"
            class="material-icons-outlined font-tiny pointer-no-bg ms-2"
          >
            arrow_forward_ios
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import {stringifiedRoles} from "@/helpers/utils/user_roles.js"
import {resetAll} from "@/helpers/utils/helper_functions";
import {ref, toRefs} from "@vue/runtime-core";
import {mapActions, mapMutations, useStore} from "vuex";
import {onMounted, toRef} from "vue";

export default {
  name: "StaffTable",
  components: { ClipLoader },
  props: ['title', 'schoolId', 'showTitle'],
  setup(props) {
    const store = useStore()
    const schoolId = toRef(props, 'schoolId')
    const showTitle = toRef(props, 'showTitle')

    const roles = stringifiedRoles
    const numberOfRows = ref(8)

    return {
      numberOfRows,
      roles,
      lodash,
      showTitle
    };
  },
  data() {
    return {
      searchFilter: '',
      searchField: '',
      sortOption: '',
      selectedUser: null,
      arrowsState: {
        nameArrowUp: false,
        nameArrowDown: false,
        genderArrowUp: false,
        genderArrowDown: false,
        sfnArrowUp: false,
        sfnArrowDown: false,
        staffTypeArrowUp: false,
        staffTypeArrowDown: false,
        teachingTypeArrowUp: false,
        teachingTypeArrowDown: false,
        gradeLevelArrowUp: false,
        gradeLevelArrowDown: false,
      },
      // staffSample:[
      //   {
      //   schoolId: 3,
      //   firstName: 'Salisu',
      //   lastName: 'Sadiq',
      //   gender: 'male',
      //   dateOfBirth: '06/22/1991',
      //   staffFileNo: '007',
      //   yearOfAppointment: 2019,
      //   yearOfPresentAppointment: 2019,
      //   typeOfStaff: 'fulltime',
      //   sourceOfSalary: 'federal',
      //   present: 'Present',
      //   schoolLevel: 'pre_primary',
      //   mainSubjectTaught: 'technology',
      //   teachJunior: 'no',
      //   teachSenior: 'no',
      //   yearOfPostingToSchool: 2019,
      //   gradeLevel: 'HND',
      //   academicQualification: 'HND',
      //   teachingQualification: 'None',
      //   areaOfSpecialization: 'Technology',
      //   teachingType: 'fulltime',

      //   attendedTrainingSeminarWorkshop: 'Yes',
      //   subjectOfQualification: 'science'
      // }
      // ]
    }
  },
  mounted(){
    const selectedSchoolId = this.schoolId ? this.schoolId : this.$store.state.school.ownSchool.id
    console.log(selectedSchoolId)
     this.fetchMySchool()
        .then(()=>{
          this.$store.dispatch('fetchSchoolStaff', selectedSchoolId)
        })
  },
  computed: {
    fetchedContent() {
      return this.$store.state.auth.schoolStaff
    },

    users() {
      if (this.fetchedContent !== null) {
        return this.fetchedContent.content
      }
      return []
    },

    message() {
      return this.$store.state.auth.message ? this.$store.state.auth.message : "No record found!"
    },

    filteredUsers() {
      const { users, searchField, filterBy, sortOption } = this
      const { nameArrowUp, nameArrowDown, genderArrowUp, genderArrowDown,
              sfnArrowUp, sfnArrowDown, staffTypeArrowUp, staffTypeArrowDown,
              teachingTypeArrowUp, teachingTypeArrowDown, gradeLevelArrowUp, gradeLevelArrowDown,
      } = this.arrowsState

      if (nameArrowUp) {
        this.sortUsers(users, "name")
      }
      if (nameArrowDown) {
        this.sortUsers(users, "name", "DSC")
      }
      if (genderArrowUp) {
        this.sortUsers(users, "gender")
      }
      if (genderArrowDown) {
        this.sortUsers(users, "gender", "DSC")
      }
      if (sfnArrowUp) {
        this.sortUsers(users, "sfn")
      }
      if (sfnArrowDown) {
        this.sortUsers(users, "sfn", "DSC")
      }
      if (staffTypeArrowUp) {
        this.sortUsers(users, "staffType")
      }
      if (staffTypeArrowDown) {
        this.sortUsers(users, "staffType", "DSC")
      }
      if (teachingTypeArrowUp) {
        this.sortUsers(users, "teachingType")
      }
      if (teachingTypeArrowDown) {
        this.sortUsers(users, "teachingType", "DSC")
      }
      if (gradeLevelArrowUp) {
        this.sortUsers(users, "gradeLevel")
      }
      if (gradeLevelArrowDown) {
        this.sortUsers(users, "gradeLevel", "DSC")
      }

      this.sortUsers(users, sortOption)
      if (users) {
        return this.users.filter(user => filterBy(user).toLowerCase().includes(searchField.toLowerCase()))
      } else {
        return []
      }

    },

    totalRecord() {
      if (this.fetchedContent !== null) {
        return this.fetchedContent.totalElements
      }
      return 0
    },

    currentPage() {
      if (this.fetchedContent !== null) {
        return this.fetchedContent.pageNumber + 1
      }
      return 0
    },

    previousPage() {
      if (this.fetchedContent !== null) {
        return this.fetchedContent.pageNumber - 1
      }
      return 0
    },

    loading() {
      return this.$store.state.auth.loading
    },

    requestError() {
      console.log(this.$store.state.auth.message);
      return this.$store.state.auth.message
    },
  },
  methods: {
    ...mapActions(["fetchSchoolStaff", "activateDeactivate", "fetchMySchool"]),
    ...mapMutations(["setLoadingStatus", "setNumberOfRows"]),
    staffProfile(user) {
      this.$emit("showStaffProfile", user);
    },

    editStaff(user) {
      this.$emit("editStaff", user);
      console.log('Edit');
    },

    fetchPagedUsers() {
      console.log("Fetching users...")
      this.fetchSchoolStaff();
    },

    onRowsChanged(event) {
      const number = event.target.value
      console.log("I'm now going to fetch " + number + " users.");
      this.setNumberOfRows(number)
      // this.fetchSchoolStaff()
    },

    fetchNext() {  
      if (this.fetchedContent.last === false) {
        // this.fetchSchoolStaff(this.currentPage)
      }
    },

    fetchPrev() {
      if (this.fetchedContent.first === false) {        
        // this.fetchUsers(this.previousPage)
      }
    },

    filterBy(user) {
      if (this.searchFilter === "" || this.searchFilter === "name") {
        return `${user.firstName} ${user.lastName}`
      }
      else if (this.searchFilter === "gender") {
        return user.gender
      }
      else if (this.searchFilter === "staffType") {
        return user.typeOfStaff
      }
      else if (this.searchFilter === "teachingType") {
        return user.teachingType
      }
      else if (this.searchFilter === "gradeLevel") {
        return user.gradeLevel
      }
      else if (this.searchFilter === "lga") {
        return user.lga
      }
      else if (this.searchFilter === "sfn") {
        return user.staffFileNo
      }
    },

    sortUsers(users, sortFilter, order = "ASC") {
      if (sortFilter) {
        switch (sortFilter) {
          case "name":
            return users.sort((user1, user2) => {
              const firstUser = `${user1.firstName} ${user1.lastName}`
              const secondUser = `${user2.firstName} ${user2.lastName}`
              if (order === "ASC") {
                return (firstUser > secondUser) ? 1 : ((secondUser > firstUser) ? -1 : 0)
              }
              return (secondUser > firstUser) ? 1 : ((firstUser > secondUser) ? -1 : 0)
            });

          case "gender":
            return users.sort((user1, user2) => {
              if (order === "ASC") {
                return (user1.gender > user2.gender) ? 1 : ((user2.gender > user1.gender) ? -1 : 0)
              }
              return (user2.gender > user1.gender) ? 1 : ((user1.gender > user2.gender) ? -1 : 0)
            });

          case "staffType":
            return users.sort((user1, user2) => {
              if (order === "ASC") {
                return (user1.typeOfStaff > user2.typeOfStaff) ? 1 : ((user2.typeOfStaff > user1.typeOfStaff) ? -1 : 0)
              }
              return (user2.typeOfStaff > user1.typeOfStaff) ? 1 : ((user1.typeOfStaff > user2.typeOfStaff) ? -1 : 0)
            });

          case "teachingType":
            return users.sort((user1, user2) => {
              if (order === "ASC") {
                return (user1.teachingType > user2.teachingType) ? 1 : ((user2.teachingType > user1.teachingType) ? -1 : 0)
              }
              return (user2.teachingType > user1.teachingType) ? 1 : ((user1.teachingType > user2.teachingType) ? -1 : 0)
            });

          case "sfn":
            return users.sort((user1, user2) => {
              if (order === "ASC") {
                return (user1.staffFileNo > user2.staffFileNo) ? 1 : ((user2.staffFileNo > user1.staffFileNo) ? -1 : 0)
              }
              return (user2.staffFileNo > user1.staffFileNo) ? 1 : ((user1.staffFileNo > user2.staffFileNo) ? -1 : 0)
            });

          case "gradeLevel":
            return users.sort((user1, user2) => {
              const levelOne = user1.gradeLevel[0]
              const levelTwo = user2.gradeLevel[0]

              if (order === "ASC") {
                return (levelOne > levelTwo) ? 1 : ((levelTwo > levelOne) ? -1 : 0)
              }
              return (levelTwo > levelOne) ? 1 : ((levelOne > levelTwo) ? -1 : 0)
            });
        }
      }
    },

    nameArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.nameArrowUp = true
    },

    nameArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.nameArrowDown = true
    },

    genderArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.genderArrowUp = true
    },

    genderArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.genderArrowDown = true
    },

    sfnArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.sfnArrowUp = true
    },

    sfnArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.sfnArrowDown = true
    },

    staffTypeArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.staffTypeArrowUp = true
    },

    staffTypeArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.staffTypeArrowDown = true
    },

    teachingTypeArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.teachingTypeArrowUp = true
    },

    teachingTypeArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.teachingTypeArrowDown = true
    },

    gradeLevelArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.gradeLevelArrowUp = true
    },

    gradeLevelArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.gradeLevelArrowDown = true
    },

    lgaArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.lgaArrowUp = true
    },

    lgaArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.lgaArrowDown = true
    },

    statusArrowUpClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.statusArrowUp = true
    },

    statusArrowDownClicked() {
      resetAll(this.arrowsState)
      this.arrowsState.statusArrowDown = true
    },

    showUpdateRoleModal(user) {
      this.selectedUser = user
      this.$emit("showUpdateRoleModal", user)
    },

    async toggleUserStatus(userId) {
      await this.activateDeactivate(userId);
    },
  },
};
</script>

<style scoped>
.big-cell {
  width: 24%;
  padding: 0;
}
.medium-cell {
  width: 18%;
  padding: 0;
}
.normal-cell {
  width: 16%;
}
.small-cell {
  width: 12%;
  padding: 0;
}
.smallest-cell {
  width: 4%;
  padding: 0;
}
</style>