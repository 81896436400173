import axios from "axios"

const initState = {
  loadingStatus: false,
  tableData: null,
  currentStage: null,
  datatableSession: null,
  message: null,
}

const state = initState

const mutations = {
  setLoadingStatus(state, value) {
    state.loadingStatus = value
  },
  setTableData(state, data) {
    state.tableData = data
  },
  setCurrentStage(state, value) {
    state.currentStage = value
  },
  setDatatableSession(state, session) {
    state.datatableSession = session
  },
  setDatatableMessage(state, message) {
    state.message = message
  },
  resetDatatableState(state) {
    Object.assign(state, initState)
  }
}

const actions = {
  async fetchAnalysis({commit}, param) {
    console.log(param);
    const {index, session} = param
    commit('setTableData', null)
    commit('setLoadingStatus', true)
    await axios.get(`analysis/load-all-analysis?index=${index}&session=${session}`)
      .then(res => {
        commit('setLoadingStatus', false)
        if (res.data.data.length > 0) {
          commit('setTableData', res.data.data)
        } else {
          commit('setDatatableMessage', res.data.message)
        }            
      })
  }
}

export default {
  state, 
  actions,
  mutations
}