<template>
  <div class="d-flex justify-content-between">
    <div
      v-if="hasBack"
      class="d-flex align-items-center pointer dm-color font-sm fw-bold"
      @click="$emit('goBack')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="10"
        viewBox="0 0 9 14.575"
      >
        <path
          id="ic_keyboard_arrow_left_24px"
          d="M17,18.362l-5.563-5.575L17,7.213,15.287,5.5,8,12.787l7.287,7.287Z"
          transform="translate(-8 -5.5)"
          fill="#0511f3"
        />
      </svg>
      <span class="ps-1 pb-1">Back</span>
    </div>
    <div class="d-flex align-items-center ms-auto">
      <span class="px-3 mb-2">
        <svg
          id="Component_128_10"
          data-name="Component 128 – 10"
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 27.047 30.396"
        >
          <path
            id="ic_notifications_none_24px"
            d="M14.256,27.5a2.572,2.572,0,0,0,2.564-2.564H11.692A2.572,2.572,0,0,0,14.256,27.5Zm7.692-7.692V13.4c0-3.936-2.09-7.231-5.769-8.1V4.423a1.923,1.923,0,0,0-3.846,0v.872c-3.667.872-5.769,4.154-5.769,8.1v6.41L4,22.372v1.282H24.513V22.372ZM19.385,21.09H9.128V13.4c0-3.179,1.936-5.769,5.128-5.769s5.128,2.59,5.128,5.769Z"
            transform="translate(2.534 2.896)"
            fill="#0030ff"
            opacity="0.8"
          />
          <g id="Component_127_2" data-name="Component 127 – 2">
            <rect
              id="Rectangle_540"
              data-name="Rectangle 540"
              width="14"
              height="14"
              rx="3"
              transform="translate(0 3.149) rotate(-13)"
              fill="#f4f5fc"
            />
            <rect
              id="Rectangle_539"
              data-name="Rectangle 539"
              width="8"
              height="8"
              rx="2"
              transform="translate(3.598 5.397) rotate(-13)"
              fill="#e36061"
            />
          </g>
        </svg>
      </span>
      <div class="me-3 mb-2 pointer-no-bg">
        <span data-bs-toggle="dropdown" aria-expanded="false">
          <svg
            id="Component_140_1"
            data-name="Component 140 – 1"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 28.023 27.957"
          >
            <rect
              id="Rectangle_637"
              data-name="Rectangle 637"
              width="6.678"
              height="6.678"
              rx="2"
              transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 2.063)"
              fill="#3157fe"
            />
            <rect
              id="Rectangle_640"
              data-name="Rectangle 640"
              width="6.678"
              height="6.678"
              rx="2"
              transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 11.8)"
              fill="#3157fe"
            />
            <rect
              id="Rectangle_643"
              data-name="Rectangle 643"
              width="6.678"
              height="6.678"
              rx="2"
              transform="matrix(0.951, -0.309, 0.309, 0.951, 0, 21.605)"
              fill="#3157fe"
            />
            <rect
              id="Rectangle_638"
              data-name="Rectangle 638"
              width="6.678"
              height="6.678"
              rx="2"
              transform="matrix(0.951, -0.309, 0.309, 0.951, 9.804, 2.063)"
              fill="#3157fe"
            />
            <rect
              id="Rectangle_641"
              data-name="Rectangle 641"
              width="6.678"
              height="6.678"
              rx="2"
              transform="matrix(0.951, -0.309, 0.309, 0.951, 9.804, 11.8)"
              fill="#3157fe"
            />
            <rect
              id="Rectangle_644"
              data-name="Rectangle 644"
              width="6.678"
              height="6.678"
              rx="2"
              transform="matrix(0.951, -0.309, 0.309, 0.951, 9.804, 21.605)"
              fill="#3157fe"
            />
            <rect
              id="Rectangle_639"
              data-name="Rectangle 639"
              width="6.678"
              height="6.678"
              rx="2"
              transform="matrix(0.951, -0.309, 0.309, 0.951, 19.608, 2.063)"
              fill="#3157fe"
            />
            <rect
              id="Rectangle_642"
              data-name="Rectangle 642"
              width="6.678"
              height="6.678"
              rx="2"
              transform="matrix(0.951, -0.309, 0.309, 0.951, 19.608, 11.8)"
              fill="#3157fe"
            />
            <rect
              id="Rectangle_645"
              data-name="Rectangle 645"
              width="6.678"
              height="6.678"
              rx="2"
              transform="matrix(0.951, -0.309, 0.309, 0.951, 19.608, 21.605)"
              fill="#3157fe"
            />
          </svg>
        </span>
        <div
          class="dropdown-menu dropdown-menu-end bg-white shadow-sm"
          aria-labelledby="featuresDropdown"
        >
          <div class="d-flex flex-wrap justify-content-start p-2 w-100">
            <div class="col-12 d-flex align-items-center p-1">
              <input
                type="text"
                name="searchFeature"
                class="form-control me-2"
                placeholder="Filter apps"
              />
              <!-- <span class="bi bi-gear font-md dm-color"></span> -->
              <span class="material-icons-outlined font-md dm-color">
                settings
              </span>
            </div>
            <div
              v-if="ability.can('manage', 'User')"
              class="
                d-flex
                flex-column
                pointer
                rounded
                align-items-center
                app
                p-2
              "
              @click="gotoUserDashboard"
            >
              <img :src="userIcon" height="24" width="24" alt="Users" />
              <span class="font-x-tiny fw-bolder">Users</span>
            </div>
            <div
              v-if="ability.can('manage', 'School') || ability.can('read', 'School')"
              class="
                d-flex
                flex-column
                pointer
                rounded
                align-items-center
                app
                p-2
              "
              @click="gotoSchoolDashboard"
            >
              <img :src="schoolIcon" height="24" width="24" alt="Schools" />
              <span class="font-x-tiny fw-bolder">Schools</span>
            </div>
            <div
              v-else-if="isSupervisor"
              class="
                d-flex
                flex-column
                pointer
                rounded
                align-items-center
                app
                p-2
              "
              @click="gotoMySchool"
            >
              <img :src="schoolIcon" height="24" width="24" alt="My school" />
              <span class="font-x-tiny fw-bolder">My Schools</span>
            </div>
            <div
              v-else
              class="
                d-flex
                flex-column
                pointer
                rounded
                align-items-center
                app
                p-2
              "
              @click="gotoMySchool"
            >
              <img :src="schoolIcon" height="24" width="24" alt="My school" />
              <span class="font-x-tiny fw-bolder">My School</span>
            </div>
            <div
              v-if="!isSupervisor && ability.can('manage', 'ASC')"
              @click="gotoASCDashboard"
              class="
                d-flex
                flex-column
                pointer
                rounded
                align-items-center
                app
                p-2
              "
            >
              <img :src="ascIcon" height="24" width="24" alt="ASC Process" />
              <span class="font-x-tiny fw-bolder">ASC</span>
            </div>
            <div
              v-if="ability.can('manage', 'AuditTrail')"
              @click="gotoAuditTrailDashboard"
              class="
                d-flex
                flex-column
                pointer
                rounded
                align-items-center
                app
                p-2
              "
            >
              <img :src="auditIcon" height="24" width="24" alt="Audit trail"  />
              <span class="font-x-tiny fw-bolder">Audit Trail</span>
            </div>
            <div
              class="
                d-flex
                flex-column
                pointer
                rounded
                align-items-center
                app
                p-2
              "
              @click="gotoDataTableDashboard"
            >
              <img :src="datatableIcon" height="24" width="24" alt="Datatable"  />
              <span class="font-x-tiny fw-bolder">Data tables</span>
            </div>
            <!-- <div
                class="
                d-flex
                flex-column
                pointer
                rounded
                align-items-center
                app
                p-2
              "
                @click="gotoComparativeAnalysisDashboard"
            >
              <img :src="comparativeIcon" height="24" width="24" alt="Comparative analysis" />
              <span class="font-x-tiny fw-bolder">Comparative</span>
            </div> -->
            <div
                class="
                d-flex
                flex-column
                pointer
                rounded
                align-items-center
                app
                p-2
              "
              @click="gotoExplore"
            >
              <img :src="exploreIcon" height="24" width="24" alt="Explore" />
              <span class="font-x-tiny fw-bolder">Explore</span>
            </div>
            <div
                class="
                d-flex
                flex-column
                pointer
                rounded
                align-items-center
                app
                p-2
              "
              @click="gotoHistoricalData"
            >
              <img :src="historicalIcon" height="24" width="24" alt="Historical data" />
              <span class="font-x-tiny fw-bolder text-nowrap">Historical Data</span>
            </div>
            <div
                class="
                d-flex
                flex-column
                pointer
                rounded
                align-items-center
                app
                p-2
              "
              @click="gotoDashboard"
            >
              <img :src="dashboardIcon" height="24" width="24" alt="Dashboard"/>
              <span class="font-x-tiny fw-bolder">Dashboard</span>
            </div>
            <div
                class="
                d-flex
                flex-column
                pointer
                rounded
                align-items-center
                app
                p-2
              "
            >
              <span class="material-icons-outlined text-dark font-lg">
                file_download
              </span>
              <span class="font-x-tiny fw-bolder">Download</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <img
          :src="avatar"
          width="32"
          height="32"
          alt="User avatar"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          class="mb-2"
        />
        <div class="dropdown-menu dropdown-menu-end py-0 shadow-sm">
          <div class="d-flex flex-column border-bottom pointer-no-bg">
            <div
              class="d-flex py-2 px-3"
              @click="gotoUserProfile"
            >
              <span class="rounded-circle p-2 bg-contrast text-white">{{userInitials }}</span>
              <div class="ms-2 d-flex flex-column">
                <span>{{ `${userMe.firstName} ${userMe.lastName}` }}</span>
                <span class="font-sm nm-t-tiny">{{ userMe.email }}</span>
              </div>
            </div>
            <span
              class="
                font-tiny
                nm-t-small
                ms-5
                ps-3
                text-decoration-underline
                mb-1"
              @click="gotoEditProfile"
              >Edit Profile</span>
          </div>
          <div
            v-if="isSupervisor"
            class="dropdown-item d-flex align-items-center my-1"
            @click="gotoMySchool"
          >
            <img :src="schoolIcon" height="20" width="20" alt="My school" />
            <span class="font-sm ms-2">My Assigned Schools</span>
          </div>
          <div
            class="dropdown-item d-flex align-items-center my-1"            
            @click="gotoAccountSetting"
          >
            <img :src="userIcon" height="20" width="20" alt="Users" />
            <span class="font-sm ms-2"> Account</span>
          </div>
          <div
            class="dropdown-item d-flex align-items-center my-1"
            @click="gotoDashboardSetting"
          >
            <span class="material-icons-outlined font-md text-dark">
              settings
            </span>
            <span class="font-sm ms-2"> Setting</span>
          </div>
          <div class="dropdown-item d-flex align-items-center my-1">            
            <span class="material-icons-outlined font-md text-dark">
              info
            </span>
            <span class="font-sm ms-2"> Help</span>
          </div>
          <div class="dropdown-item d-flex align-items-center my-1" @click="logout">        
            <span class="material-icons-outlined font-md text-dark">
              logout
            </span>
            <span class="font-sm ms-2"> Logout</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import router from "@/router";
import customAvatar from "@/assets/images/avatar.png";
import userIcon from "@/assets/images/user.png";
import schoolIcon from "@/assets/images/school.png"
import auditIcon from "@/assets/images/audit.png"
import exploreIcon from "@/assets/images/explore.png"
import dashboardIcon from "@/assets/images/dashboard.png"
import ascIcon from "@/assets/images/asc.png"
import comparativeIcon from "@/assets/images/comparative.png"
import datatableIcon from "@/assets/images/datatable.png"
import historicalIcon from "@/assets/images/historical.png"
import dashboardMixin from "../../helpers/mixins/dashboardMixin.vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'
import defineAbilityFor from "@/authorities";
import { computed } from "@vue/runtime-core";
import Roles from "@/authorities/roles";

export default {
  name: "DashboardHeader",
  props: ["hasBack"],
  mixins: [dashboardMixin],
  setup() {
    const store = useStore();
    const router = useRouter()
    const userMe = computed(() => store.getters.loginUser).value;
    const isSupervisor = userMe.role === Roles.supervisor
    const avatar = customAvatar;
    const ability = defineAbilityFor(userMe);
    const userInitials = computed(
      () =>
        userMe.firstName.toUpperCase().charAt(0) +
        userMe.lastName.toUpperCase().charAt(0)
    )

    const logout = () => {
      store.commit('resetAuthState')
      store.commit('resetSectionState')
      store.commit('resetAnalysisState')
      store.commit('resetExploreState')
      store.commit('resetEnrolmentState')
      store.commit('resetServiceState')
      store.commit('resetQuestionnaireStageState')
      store.commit('resetSchoolState')
      store.commit('resetDatatableState')
      
      router.push("/login");
    }

    return {
      avatar,
      userMe,
      ability,
      userInitials,
      isSupervisor,

      userIcon,
      schoolIcon,
      auditIcon,
      exploreIcon,
      ascIcon,
      dashboardIcon,
      comparativeIcon,
      datatableIcon,
      historicalIcon,

      logout,
    };
  },
};
</script>
