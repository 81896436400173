<template>
  <div>
    <div v-if="loading" class="overlay">
      <clip-loader class="loading"></clip-loader>
    </div>
    <p class="font-md fw-bold my-4">Staff</p>
    <div class="d-flex radius-half bg-white shadow-sm py-3 px-5 mb-3">
      <div class="col-12 d-flex flex-column mx-3 pe-3">
        <span class="font-tiny fw-bold mb-2">Search staff by file number?</span>
        <div class="d-flex w-100">
          <div class="custom-input-group bg-accent col-9">
            <span class="p-1"><i class="bi bi-search grey-shade"></i></span>
            <input
                type="text"
                v-model="fileNumber"
                class="ph-sm no-outline-input font-tiny fw-bolder"
                placeholder="Search if staff already exist..."
            />
          </div>
          <span
            @click="findStaff"
            class="btn btn-primary text-center mx-3 py-2 font-sm"
          >
            Find staff
          </span>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
      <div class="row justify-content-between px-2 pb-4">
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> First name </label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter staff first name"
            v-model="staffInfo.staffFirstName"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Last name </label>
          <input
              type="text"
              class="form-control"
              placeholder="Enter staff last name"
              v-model="staffInfo.staffLastName"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Staff file number</label>
          <input
            type="number"
            class="form-control"
            placeholder="Enter staff file number"
            v-model="staffInfo.staffFileNumber"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Gender </label>
          <select class="form-select" aria-label="Gender" v-model="staffInfo.gender">
            <option value="">Select gender</option>
            <option value="MALE">Male</option>
            <option value="FEMALE">Female</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Source of salary </label>
          <select class="form-select" aria-label="Type of school" v-model="staffInfo.salarySource">
            <option value="">Select source of salary</option>
            <option value="FG_FTS">Federal government</option>
            <option value="SG_ON_THIS_SCHOOL_PAYROLL">
              State government - On this school's payroll
            </option>
            <option value="SG_ON_ANOTHER_SCHOOL_PAYROLL">
              State government - On another school's payroll
            </option>
            <option value="COMMUNITY">Community</option>
            <option value="PTA">PTA</option>
            <option value="NYSC">NYSC</option>
            <option value="NO_SALARY">No salary</option>
            <option value="OTHER">Other</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Year of birth </label>
          <input
            class="form-control"
            type="date"
            placeholder="Date of birth"
            v-model="staffInfo.dateOfBirth" />
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            What level(s) does this staff teaches?
          </label>
          <div class="d-flex">
            <div class="col-3 pe-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="PRE_PRIMARY" v-model="staffInfo.levelsTaught" id="pre-primary">
                <label class="form-check-label font-sm" for="pre-primary">
                  Pre-Primary
                </label>
              </div>
            </div>
            <div class="col-3 pe-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="PRIMARY" v-model="staffInfo.levelsTaught" id="primary">
                <label class="form-check-label font-sm" for="primary">
                  Primary
                </label>
              </div>
            </div>
            <div class="col-3 pe-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="JUNIOR" v-model="staffInfo.levelsTaught" id="junior">
                <label class="form-check-label font-sm" for="junior">
                  Junior
                </label>
              </div>
            </div>
            <div class="col-3">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="SENIOR" v-model="staffInfo.levelsTaught" id="senior">
                <label class="form-check-label font-sm" for="senior">
                  Senior
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Year of first appointment
          </label>
          <select
            class="form-select"
            aria-label="Year of first appointment."
            v-model="staffInfo.yearOfFirstAppointment"
          >
            <option value="">Select year of first appointment</option>
            <option v-for="year in feasibleYears" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Year of current appointment
          </label>
          <select
            class="form-select"
            aria-label="Year of current appointment"
            v-model="staffInfo.yearOfCurrentAppointment"
          >
            <option value="">Select year of current appointment</option>
            <option v-for="year in feasibleYears" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Year of posting to this school
          </label>
          <select
            class="form-select"
            aria-label="Year of posting to this school"
            v-model="staffInfo.yearOfPostingToSchool"
          >
            <option value="">Select year of posting to this school</option>
            <option v-for="year in feasibleYears" :key="year" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Grade level/step </label>
          <select class="form-select" aria-label="Presence" v-model="staffInfo.gradeLevel">
            <option value="">Select teacher's presence</option>
            <option value="GRADE_TWO">Grade 2</option>
            <option value="GRADE_THREE">Grade 3</option>
            <option value="GRADE_FOUR">Grade 4</option>
            <option value="GRADE_FIVE">Grade 5</option>
            <option value="GRADE_SIX">Grade 6</option>
            <option value="GRADE_SEVEN">Grade 7</option>
            <option value="GRADE_EIGHT">Grade 8</option>
            <option value="GRADE_NINE">Grade 9</option>
            <option value="GRADE_TEN">Grade 10</option>
            <option value="GRADE_TWELVE">Grade 12</option>
            <option value="GRADE_THIRTEEN">Grade 13</option>
            <option value="GRADE_FOURTEEN">Grade 14</option>
            <option value="GRADE_FIFTEEN">Grade 15</option>
            <option value="GRADE_SIXTEEN">Grade 16</option>
            <option value="GRADE_SEVENTEEN">Grade 17</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Presence </label>
          <select class="form-select" aria-label="Presence" v-model="staffInfo.present">
            <option value="">Select teacher's presence</option>
            <option value="PRESENT">
              Present
            </option>
            <option value="TEMPORARILY_ABSENT">
              Temporarily absent
            </option>
            <option value="MATERNITY_LEAVE_OR_ABSENT_FOR_MORE_THAN_1_MONTH">
              Absent from more than 1 month - Maternity leave
            </option>
            <option value="SICK_LEAVE_OR_ABSENT_FOR_MORE_THAN_1_MONTH">
              Absent from more than 1 month - Sick leave
            </option>
            <option value="TRAINING_ABSENT_FOR_MORE_THAN_1_MONTH">
              Absent from more than 1 month - Training
            </option>
            <option value="UNAUTHORIZED">
              Absent from more than 1 month - Unauthorised
            </option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Academic qualification
          </label>
          <select
            class="form-select"
            aria-label="Academic qualification"
            v-model="staffInfo.academicQualification"
          >
            <option value="">Select academic qualification</option>
            <option value="BELOW_SSCE">Below SSCE</option>
            <option value="SSCE">SSCE</option>
            <option value="WASC">WASC</option>
            <option value="OND_DIPLOMA">OND/Diploma</option>
            <option value="NCE">NCE</option>
            <option value="PGDE">Degree/HND graduate</option>
            <option value="BACHELOR_OF_EDUCATION">BSc. Education</option>
            <option value="MASTER_OF_EDUCATION">MSc. Education</option>
            <option value="GRADE_II">GRADE II</option>
            <option value="BA_ED">BA. Education</option>
            <option value="BSC_EDU">BSc. Edu</option>
            <option value="DEGREE_OR_HND_OR_GRADUATE">Degree Or HND Or Graduate </option>
            <option value="MASTER_OR_PHD">PhD/Masters degree</option>
            <option value="FSLS">FSLS</option>
            <option value="OTHER_DEGRESS_GRADUATE">Other</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Teaching qualification
          </label>
          <select class="form-select" aria-label="Teaching qualification" v-model="staffInfo.teachingQualification">
            <option value="">Select teaching qualification</option>
            <option value="NCE">NCE</option>
            <option value="PGDE">PGDE</option>
            <option value="B_ED">B.Ed or equivalent</option>
            <option value="M_ED">M.Ed or equivalent</option>
            <option value="GRADE_2_OR_ITS_EQUIVALENT">
              Grade II or equivalent
            </option>
            <option value="NONE">None</option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Subject of qualification
          </label>
          <select class="form-select" aria-label="Subject of qualification" v-model="staffInfo.subjectOfQualification">
            <option v-if="staffInfo.subjectOfQualification" :value="staffInfo.subjectOfQualification" selected> {{ lodash.capitalize(staffInfo.subjectOfQualification) }} </option>
            <option v-else value="">Select subject of qualification</option>
            <option
              v-for="(subject, index) in subjects"
              :key="index"
              :value="subject"
            >
              {{ subject }}
            </option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Area of specialization
          </label>
          <select class="form-select" aria-label="Area of specialization" v-model="staffInfo.areaOfSpecialization">
            <option v-if="staffInfo.areaOfSpecialization" :value="staffInfo.areaOfSpecialization" selected> {{ lodash.capitalize(staffInfo.areaOfSpecialization) }} </option>
            <option v-else value="">Select area of specialization</option>
            <option
              v-for="(subject, index) in subjects"
              :key="index"
              :value="subject"
            >
              {{ subject }}
            </option>
          </select>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Main subject taught
          </label>
          <select class="form-select" aria-label="Main subject taught" v-model="staffInfo.mainSubjectTaught">
            <option v-if="staffInfo.mainSubjectTaught" :value="staffInfo.mainSubjectTaught" selected> {{ lodash.capitalize(staffInfo.mainSubjectTaught) }} </option>
            <option v-else value="">Select main subject taught</option>
            <option
              v-for="(subject, index) in subjects"
              :key="index"
              :value="subject"
            >
              {{ subject }}
            </option>
          </select>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Type of staff
          </label>
          <select class="form-select" aria-label="Main subject taught" v-model="staffInfo.typeOfStaff">
            <option v-if="staffInfo.typeOfStaff" :value="staffInfo.typeOfStaff" selected> {{ lodash.capitalize(staffInfo.typeOfStaff) }} </option>
            <option v-else value="">Select staff type</option>
            <option value="PRINCIPAL">Principal</option>
            <option value="VICE_PRINCIPAL">Vice principal</option>
            <option value="TEACHER">Teacher</option>
            <option value="NON_TEACHING_STAFF">Non teaching</option>
            <option value="HEAD_TEACHER">Head teacher</option>
            <option value="ASSISTANT_HEAD_TEACHER">Assistant head teacher</option>
            <option value="CARE_GIVER">Caregiver</option>
            <option value="OTHER">Other non-teaching staff</option>
          </select>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal"> Teaching type </label>
          <div class="d-flex">
            <div class="col-4 pe-2">
              <div class="form-check" aria-label="Type of teaching is full-time">
                <input
                  class="form-check-input"
                  type="radio"
                  name="typeOfTeaching"
                  v-model="staffInfo.teachingType"
                  value="FULL_TIME"
                  id="full-time"
                />
                <label class="form-check-label font-sm" for="full-time">
                  Full-time
                </label>
              </div>
            </div>
            <div class="col-4 pe-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="typeOfTeaching"
                  v-model="staffInfo.teachingType"
                  value="PART_TIME"
                  id="part-time"
                />
                <label class="form-check-label font-sm" for="part-time">
                  Part-time
                </label>
              </div>
            </div>
            <div class="col-4 ps-2">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="typeOfTeaching"
                    v-model="staffInfo.teachingType"
                    value="VOLUNTEER"
                    id="volunteer"
                />
                <label class="form-check-label font-sm" for="part-time">
                  Volunteer
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Does this teacher teach junior classes in
            this school?
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="teachesJunior"
                  v-model="staffInfo.teachesJunior"
                  value="true"
                  id="takeJunior"
                />
                <label class="form-check-label font-sm" for="takeJunior">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="teachesJunior"
                  v-model="staffInfo.teachesJunior"
                  value="false"
                  id="DoesNotTakeJunior"
                />
                <label class="form-check-label font-sm" for="DoesNotTakeJunior">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Does this teacher teach senior classes in
            this school?
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="teachesSenior"
                    v-model="staffInfo.teachesSenior"
                    value="true"
                    id="takesSenior"
                />
                <label class="form-check-label font-sm" for="takesSenior">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    name="teachesSenior"
                    v-model="staffInfo.teachesSenior"
                    value="false"
                    id="DoesNot"
                />
                <label class="form-check-label font-sm" for="DoesNot">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Has this teacher attended workshops in the past 12 months?
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="hasAttendedWorkshop"
                  v-model="staffInfo.hasAttendedWorkshop"
                  value="true"
                  id="hasAttended"
                />
                <label class="form-check-label font-sm" for="hasAttended">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="hasAttendedWorkshop"
                  v-model="staffInfo.hasAttendedWorkshop"
                  value="false"
                  id="hasNot"
                />
                <label class="form-check-label font-sm" for="hasNot">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <div class="d-flex" v-if="staffSearchResult">
          <button class="custom-btn font-sm fw-bold me-3" @click="updateStaffInfo">Update</button>
          <button class="custom-btn font-sm fw-bold" @click="skipAndAddNew">Add new</button>
        </div>
        <button v-else class="custom-btn font-sm fw-bold" @click="saveAndAddNewStaff">Save</button>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <span
        class="dm-color font-sm fw-bold pointer-no-bg"
        @click="$emit('continue')"
      >Continue</span>
    </div>

    <transition name="modal">
      <modal v-if="hasMessage" @close="closeModal">
        <template v-slot:body>
          <p>{{ message }}</p>
        </template>
      </modal>
    </transition>

    <transition name="modal">
      <modal v-if="searchResultMessage" @close="closeModal">
        <template v-slot:body>
          <p>{{ searchMessage }}</p>
        </template>
      </modal>
    </transition>

  </div>
</template>

<script>
import lodash from "lodash";
import { range } from "@/helpers/utils/helper_functions";
import {mapActions, mapMutations, useStore} from "vuex";
import { computed } from "@vue/runtime-core";
import Modal from "@/helpers/utils/Modal";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
  name: "SchoolStaff",
  emits: ['reload', 'continue'],
  components: { Modal, ClipLoader },
  props: ['staffData', 'operation', 'showTitle'],
  setup() {
    const store = useStore();
    const today = new Date()
    const currentYear = today.getFullYear() + 1
    const feasibleYears = range(currentYear - 40, currentYear);
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const subjectsForPrimary = ["English", "Mathematics", "Social studies", "Basic science", "Hausa/Igbo/Yoruba", "Caregiving"]
    const subjectsForJSS = ["English", "Mathematics", "Social studies", "Basic science", "Hausa/Igbo/Yoruba"]
    const subjectsForSSS = ["Science", "Humanities", "Technology"]
    const higherSubjects = [...new Set([...subjectsForJSS, ...subjectsForSSS])]
    const others = ["Others", "None"]
    let levels = []
    if (schoolInfo !== null) {
      levels = schoolInfo.levelsOffered.map(item => item.toLowerCase())
    }
    const hasPreNPrimary = levels.includes("primary")
    const hasJSS = levels.includes("junior")
    const hasSSS = levels.includes("senior")
    let subjects = []
    if (hasPreNPrimary) {
      subjects = [...new Set([...subjectsForPrimary])]
    }
    if (hasJSS) {
      subjects = [...new Set([...subjects, ...subjectsForJSS])]
    }
    if (hasSSS) {
      subjects = [...new Set([...subjects, ...subjectsForSSS])]
    }

    if (subjects.length === 0) {
      subjects = [...new Set([...subjectsForPrimary, ...subjectsForJSS, ...subjectsForSSS])]
    }
    subjects = [...subjects, ...others]
    
    const schoolStaff = computed(
      () => store.state.questionnaire.staffData
    ).value;

    const staffSearchResult = computed(() => store.state.auth.staffSearchResult).value

    let id = '', schoolId = '', attendedTrainingSeminarWorkshop = '', firstName = '', lastName = ''
    let gender = '', dateOfBirth = '', yearOfAppointment = '', yearOfPresentAppointment = '', staffFileNo = ''
    let typeOfStaff = '', sourceOfSalary = '', present = '', teachJunior = '', teachSenior = '', yearOfPostingToSchool = ''
    let gradeLevel = '', academicQualification = '', teachingQualification = '', subjectOfQualification = ''
    let areaOfSpecialization = '', mainSubjectTaught = '', teachingType = '', schoolLevel = []

    if (staffSearchResult !== null) {
      ({id, schoolId, attendedTrainingSeminarWorkshop, firstName, lastName, gender, dateOfBirth,
        yearOfAppointment, yearOfPresentAppointment, staffFileNo, typeOfStaff, sourceOfSalary, present,
        teachJunior, teachSenior, yearOfPostingToSchool, gradeLevel, academicQualification, teachingQualification,
        subjectOfQualification, areaOfSpecialization, mainSubjectTaught, teachingType, schoolLevel
      } = staffSearchResult)
    }

    let staffInfo = {
      schoolId: schoolId,
      staffFirstName: firstName,
      staffLastName: lastName,
      staffFileNumber: staffFileNo,
      gender: gender,
      salarySource: sourceOfSalary,
      dateOfBirth: dateOfBirth,
      levelsTaught: schoolLevel,
      yearOfFirstAppointment: yearOfAppointment,
      yearOfCurrentAppointment: yearOfPresentAppointment,
      yearOfPostingToSchool: yearOfPostingToSchool,
      gradeLevel: gradeLevel,
      present: present,
      academicQualification: academicQualification,
      teachingQualification: teachingQualification,
      areaOfSpecialization: areaOfSpecialization,
      subjectOfQualification: subjectOfQualification,
      mainSubjectTaught: mainSubjectTaught,
      typeOfStaff: typeOfStaff,
      teachingType: teachingType,
      teachesJunior: teachJunior,
      teachesSenior: teachSenior,
      hasAttendedWorkshop: attendedTrainingSeminarWorkshop,
    };

    return {
      feasibleYears,
      subjects,
      staffInfo,
      schoolStaff,
      subjectsForPrimary,
      higherSubjects,
      staffSearchResult,
      id
    };
  },
  data() {
    return {
      levelsTaught: [],
      fileNumber: "",
      lodash,
      staffSample:[
        {
        schoolId: 1,
        firstName: 'Salisu',
        lastName: 'Sadiq',
        gender: 'male',
        dateOfBirth: '06/22/1991',
        staffFileNo: '007',
        yearOfAppointment: 2019,
        yearOfPresentAppointment: 2019,
        typeOfStaff: 'fulltime',
        sourceOfSalary: 'federal',
        present: 'Present',
        schoolLevel: 'pre_primary',
        mainSubjectTaught: 'technology',
        teachJunior: 'no',
        teachSenior: 'no',
        yearOfPostingToSchool: 2019,
        gradeLevel: 'HND',
        academicQualification: 'HND',
        teachingQualification: 'None',
        areaOfSpecialization: 'Technology',
        teachingType: 'fulltime',

        attendedTrainingSeminarWorkshop: 'Yes',
        subjectOfQualification: 'science'
      }
      ]
    }
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },

    loading() {
      return this.$store.state.auth.loading
    },

    searchMessage() {
      return this.$store.state.auth.message
    },

    searchResultMessage() {
      return this.searchMessage !== null
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
    hasMessage() {
      return this.message !== null
    },

    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
  },
  methods: {
    ...mapActions(['submitStaffInfo', 'findStaffByFileNumber', 'updateStaffDetail']),
    ...mapMutations(['setStaffData', 'setQAMessage', 'setMessage',
      'setResponseCode', 'setStaffSearchResult']),

    // saveStaff() {
    //   this.setStaffData(this.staffInfo);
    //   this.$emit("saveStaff");
    // },

    async findStaff() {
      if (this.fileNumber.trim() !== "") {
        await this.findStaffByFileNumber(this.fileNumber)
        .then(() => {
          this.$emit('reload')
        })
      } else {
        alert("Please enter staff file number.")
      }
    },

    async saveAndAddNewStaff() {
      const staffData = {
        schoolId: this.ownSchool.id,
        firstName: this.staffInfo.staffFirstName,
        lastName: this.staffInfo.staffLastName,
        gender: this.staffInfo.gender,
        dateOfBirth: this.staffInfo.dateOfBirth,
        staffFileNo: this.staffInfo.staffFileNumber,
        yearOfAppointment: this.staffInfo.yearOfFirstAppointment,
        yearOfPresentAppointment: this.staffInfo.yearOfCurrentAppointment,
        typeOfStaff: this.staffInfo.typeOfStaff,
        sourceOfSalary: this.staffInfo.salarySource,
        present: this.staffInfo.present,
        schoolLevel: this.staffInfo.levelsTaught,
        mainSubjectTaught: this.staffInfo.mainSubjectTaught.toUpperCase().replace(' ', '_'),
        teachJunior: this.staffInfo.teachesJunior,
        teachSenior: this.staffInfo.teachesSenior,
        yearOfPostingToSchool: this.staffInfo.yearOfPostingToSchool,
        gradeLevel: this.staffInfo.gradeLevel,
        academicQualification: this.staffInfo.academicQualification,
        teachingQualification: this.staffInfo.teachingQualification,
        areaOfSpecialization: this.staffInfo.areaOfSpecialization.toUpperCase().replace(' ', '_'),
        teachingType: this.staffInfo.teachingType,

        attendedTrainingSeminarWorkshop: this.staffInfo.hasAttendedWorkshop,
        subjectOfQualification: this.staffInfo.subjectOfQualification.toUpperCase().replace(' ', '_')
      }
      await this.submitStaffInfo(staffData)
      .then(() => {
        this.$notification.open({
              type: this.status,
              message: this.message,
            })
        if (this.responseCode === 201) {
          this.$emit('reload')
        }
      })
    },

    skipAndAddNew() {
      this.setStaffSearchResult(null)
      this.$emit('reload')
    },

    updateStaffInfo() {
      const staffData = {
        schoolId: this.ownSchool.id,
        firstName: this.staffInfo.staffFirstName,
        lastName: this.staffInfo.staffLastName,
        gender: this.staffInfo.gender,
        dateOfBirth: this.staffInfo.dateOfBirth,
        staffFileNo: this.staffInfo.staffFileNumber,
        yearOfAppointment: this.staffInfo.yearOfFirstAppointment,
        yearOfPresentAppointment: this.staffInfo.yearOfCurrentAppointment,
        typeOfStaff: this.staffInfo.typeOfStaff,
        sourceOfSalary: this.staffInfo.salarySource,
        present: this.staffInfo.present,
        schoolLevel: this.staffInfo.levelsTaught,
        mainSubjectTaught: this.staffInfo.mainSubjectTaught.toUpperCase().replace(' ', '_'),
        teachJunior: this.staffInfo.teachesJunior,
        teachSenior: this.staffInfo.teachesSenior,
        yearOfPostingToSchool: this.staffInfo.yearOfPostingToSchool,
        gradeLevel: this.staffInfo.gradeLevel,
        academicQualification: this.staffInfo.academicQualification,
        teachingQualification: this.staffInfo.teachingQualification,
        areaOfSpecialization: this.staffInfo.areaOfSpecialization.toUpperCase().replace(' ', '_'),
        teachingType: this.staffInfo.teachingType,

        attendedTrainingSeminarWorkshop: this.staffInfo.hasAttendedWorkshop,
        subjectOfQualification: this.staffInfo.subjectOfQualification.toUpperCase().replace(' ', '_')
      }
      const requestObject = {
        id: this.id,
        body: staffData
      }
      this.updateStaffDetail(requestObject)
      .then(() => {
        if (this.responseCode === 204) {
          this.setStaffSearchResult(null)
          this.$emit('reload')
        }
      })
    },

    closeModal() {
      this.setQAMessage(null)
      this.setMessage(null)
      this.setResponseCode(null)
    },
  },
};
</script>

<style scoped>
</style>