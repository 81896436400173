<template>
  <div class="d-flex flex-column">
    <p class="fw-bold grey-accent mt-4 pt-2 mb-2">School Information</p>
    <div
      v-if=" title === undefined && ability.can('manage', 'School')"
      class="d-flex justify-content-end align-items-center mt-1 mb-2">
      <div class="dropdown">
        <span
          class="dropdown-toggle grey-accent font-tiny"
          type="button"
          id="options"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="bi bi-three-dots-vertical"></i> Option
        </span>
        <ul
          class="
            dropdown-menu dropdown-menu-end
            bg-accent
            shadow
            font-tiny
            radius-half
          "
          aria-labelledby="options"
        >
          <li>
            <a class="dropdown-item grey-accent" href="#"
              ><i class="bi bi-pencil-fill me-2"></i> Assign Admin</a
            >
          </li>
          <li>
            <a class="dropdown-item grey-accent" href="#"
              ><i class="bi bi-person-circle me-2"></i> Perform Census</a
            >
          </li>
          <li>
            <a class="dropdown-item grey-accent" href="#"
              ><i class="bi bi-x-lg me-2"></i> Deactivate School</a
            >
          </li>
        </ul>
      </div>
      <span
        class="grey-accent pointer-no-bg font-tiny px-3 mt-1"
        @click="$emit('editSchoolInfo', school.schoolCode)"
      >
        <i class="bi bi-pencil-fill"></i> Edit
      </span>
    </div>

    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white ">
      <div class="row justify-content-between px-2 pb-4">
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            School code
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.schoolCode"
          />
        </div>
        <div class="col-4 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            School coordinates
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.elevation"
          />
        </div>
        <div class="col-4 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
          Longitude
        </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.longitude"
          />
        </div>
        <div class="col-4 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
          Latitude
        </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.latitude"
          />
        </div>

        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            School name
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.schoolName"
          />
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            School type
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.schoolType"
          />
        </div>  

        
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            School classification
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.classification"
          />
        </div>        

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Street name
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.streetName"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Street number
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.streetNumber"
          />
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Location
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.location"
          />
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Name of village/town
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.villageName"
          />
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Local government area
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.lga"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Ward
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.ward"
          />
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            School telephone
          </label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="schoolInfo.telephone"
          />
        </div>

        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            School email address
          </label>
          <input
            disabled
            type="email"
            class="form-control"
            v-model="schoolInfo.email"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import avatar from "@/assets/images/classroompix.png";
import {computed, toRef} from "@vue/runtime-core";
import defineAbilityFor from "@/authorities";
import {useStore} from "vuex";

export default {
  components: {},
  props: ['school', 'title'],
  emits: ['reload'],
  setup(props, {emit}) {
    const store = useStore()
    const userMe = computed(() => store.getters.loginUser).value;
    const ability = defineAbilityFor(userMe);
    let schoolCode = "", elevation = "", schoolName = "", lga = "", ward = "", state = "", streetNumber = "", schoolType = ""
    let streetName = "", email = "", location = "", schoolTelephone = "", villageOrTown = "", longitude = "", latitude = ""
    let classification = ""
    let schoolSelected = toRef(props, 'school')
    const school = schoolSelected.value
    if (school !== null) {
      ({
          schoolCode, elevation, schoolName, lga, ward, state, streetName, streetNumber, email, 
          schoolTelephone, villageOrTown, longitude, latitude, location, schoolType, classification
        } = school
      )
    }
    else {
      emit('reload')
    }
    const dummyAvatar = avatar;
    const schoolInfo = {
      schoolCode: schoolCode,
      elevation: elevation,
      schoolName: schoolName,
      streetName: streetName,
      streetNumber: streetNumber,
      schoolType: schoolType,
      classification: classification,
      lga: lga,
      ward: ward,
      state: state,      
      email: email,
      telephone: schoolTelephone,
      villageName: villageOrTown,
      longitude: longitude,
      latitude: latitude,
      location: location
    };
    return {
      dummyAvatar,
      schoolInfo,
      ability
    };
  },
  mounted(){
    console.log(this.school)
  }
};
</script>
