<template>
  <div class="mt-5">
    <div v-if="loading" class="overlay">
      <moon-loader class="loading"></moon-loader>
    </div>
    <p class="font-md fw-bold mb-2">Planning</p>
    <ASCPlanningTable :planningData="planningData" v-if="planninExist" @reFetchPlanning="fetchPlanning" />
    <div v-else>
      <form @submit.prevent="submitPlanning" method="post">
        <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
          <div class="col-12 d-flex mt-3 px-3">
            <div class="col-3">
              <label class="form-label font-sm fw-normal">
                Start Date
              </label>
            </div>
            <div class="col">
              <input v-model="startDate" type="date" class="form-control" placeholder="Select start date" required />
            </div>
          </div>
          <div class="col-12 d-flex mt-3 px-3">
            <div class="col-3">
              <label class="form-label font-sm fw-normal">
                Questionnaire
              </label>
            </div>
            <div class="col-4">
              <input v-model="questionaireStart" type="date" class="form-control" placeholder="Select start date" required />
            </div>
            <div class="col-1 text-center pt-2">
              <span>to</span>
            </div>
            <div class="col-4">
              <input v-model="questioniareEnd" type="date" class="form-control" placeholder="Select end date" required />
            </div>
          </div>
          <div class="col-12 d-flex mt-3 px-3">
            <div class="col-3">
              <label class="form-label font-sm fw-normal">
                LGA Validation
              </label>
            </div>
            <div class="col-4">
              <input v-model="lgaValidationStart" type="date" class="form-control" placeholder="Select start date" required />
            </div>
            <div class="col-1 text-center pt-2">
              <span>to</span>
            </div>
            <div class="col-4">
              <input v-model="lgaValidatiionEnd" type="date" class="form-control" placeholder="Select end date" required />
            </div>
          </div>
          <div class="col-12 d-flex mt-3 px-3">
            <div class="col-3">
              <label class="form-label font-sm fw-normal">
                State Validation
              </label>
            </div>
            <div class="col-4">
              <input v-model="stateValidationStart" type="date" class="form-control" placeholder="Select start date"
                required />
            </div>
            <div class="col-1 text-center pt-2">
              <span>to</span>
            </div>
            <div class="col-4">
              <input v-model="stateValidationEnd" type="date" class="form-control" placeholder="Select end date" required />
            </div>
          </div>
          <div class="col-12 d-flex mt-3 px-3">
            <div class="col-3">
              <label class="form-label font-sm fw-normal">
                Report Generation
              </label>
            </div>
            <div class="col">
              <input v-model="reportGenerationDate" type="date" class="form-control" placeholder="Select date" required />
            </div>
          </div>
          <div class="col-12 d-flex mt-3 px-3">
            <div class="col-3">
              <label class="form-label font-sm fw-normal">
                Session
              </label>
            </div>
            <div class="col">
              <select class="form-select" v-model="session">
                <option v-for="(session, index) in feasibleSessions" :key="index" :value="session">{{ session }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center">
          <button class="custom-btn font-tiny fw-bold" type="submit">Submit</button>
        </div>
      </form>
    </div>
    <!-- <a-modal :visible="hasMessage" title="Planning message" @cancel="handleOkay" @ok="handleOkay">
      <p>{{ message }}</p>
    </a-modal> -->
  </div>
</template>

<script>
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import {notification } from "ant-design-vue"
import {computed, ref, onMounted} from "vue"
import { useStore } from 'vuex'
import ASCPlanningTable from '@/components/dashboardPage/school/ASCDashboard/ASCPlanningTable'

export default {
  name: "ASCPlanning",
  components: {
    MoonLoader,
    ASCPlanningTable,    
  },
  setup() {  
    const store = useStore()
    const loading = computed(() => store.state.asc.loading)
    const updateMessage = computed(() =>store.state.asc.updateMessage)
    const message = computed(() => updateMessage.message !== null ? updateMessage.message : '' )
    const hasMessage = computed(() => message.value != null)
    const activePlanning = computed(()=> store.state.asc.activePlanning)
    const today = new Date()
    const currentYear = today.getFullYear()

    const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) =>  `${(start + (i * step)) + "/" + (start + 1 + (i * step)) }`);
    
    const feasibleSessions = range(currentYear - 10, currentYear + 10, 1)

    const startDate = ref('')
    const questionaireStart = ref('')
    const questioniareEnd = ref('')
    const lgaValidationStart = ref('')
    const lgaValidatiionEnd = ref('')
    const stateValidationStart = ref('')
    const stateValidationEnd = ref('')
    const reportGenerationDate = ref('')
    const session = ref('')
    const showPlanningTable = ref(true)
    const planningData = ref('')
    const planninExist = ref(false)

    const fetchPlanning = () => {
      store.dispatch('checkPlanning').then(() => {
        // console.log(updateMessage.value.status)
        if (updateMessage.value.status === 'error' && updateMessage.value.message === 'Asc Planning Not Found!'){
          planningData.value = ''
          planninExist.value = false
          notification.open({
            type: updateMessage.value.status,
            message: updateMessage.value.message,
          })
        }
        
      })
      if (store.state.asc.activePlanning !== null && store.state.asc.activePlanning !== undefined) {
        planningData.value = store.state.asc.activePlanning
        planninExist.value = true
        session.value = store.state.asc.activePlanning.session
        lgaValidationStart.value = store.state.asc.activePlanning.lgaValidationStart
        lgaValidatiionEnd.value = store.state.asc.activePlanning.lgaValidatiionEnd
        questionaireStart.value = store.state.asc.activePlanning.questionaireStart
        questioniareEnd.value = store.state.asc.activePlanning.questioniareEnd
        reportGenerationDate.value = store.state.asc.activePlanning.reportGenerationDate
        stateValidationStart.value = store.state.asc.activePlanning.stateValidationStart
        stateValidationEnd.value = store.state.asc.activePlanning.stateValidationEnd
        startDate.value = store.state.asc.activePlanning.startDate
      } else {
        planninExist.value = false
        planningData.value = ''
      }
    }
    onMounted(() =>{
      planninExist.value = false
      fetchPlanning()
    })
    const submitPlanning = () => {
      const body = {
         "startDate": startDate.value,
         "questionaireStart": questionaireStart.value,
         "questioniareEnd": questioniareEnd.value,
         "lgaValidationStart": lgaValidationStart.value,
         "lgaValidatiionEnd": lgaValidatiionEnd.value,
         "stateValidationStart": stateValidationStart.value,
         "stateValidationEnd": stateValidationEnd.value,
         "reportGenerationDate": reportGenerationDate.value,
         "session": session.value,
      }
      store.dispatch('submitPlanning', body).then(()=>{
        notification.open({
          type: updateMessage.value.status,
          message: updateMessage.value.message
        })
        fetchPlanning()
      })
     
    }


    const  updatePlanning = () => {
      const body = {
        "id": store.state.asc.activePlanning.id,
        "startDate": startDate.value,
        "questionaireStart": questionaireStart.value,
        "questioniareEnd": questioniareEnd.value,
        "lgaValidationStart": lgaValidationStart.value,
        "lgaValidatiionEnd": lgaValidatiionEnd.value,
        "stateValidationStart": stateValidationStart.value,
        "stateValidationEnd": stateValidationEnd.value,
        "reportGenerationDate": reportGenerationDate.value,
        "session": session.value,
      }
      store.dispatch('updatePlanning', body)
      store.dispatch('checkPlanning')
    }

    const handleOkay = () => {
      store.commit('setASCPlanningMessage', null)
    }

    return {
      startDate,
      questionaireStart,
      questioniareEnd,
      lgaValidationStart,
      lgaValidatiionEnd,
      stateValidationStart,
      stateValidationEnd,
      reportGenerationDate,
      feasibleSessions,
      session,
      loading,
      message,    
      hasMessage,
      activePlanning,
      updatePlanning,
      submitPlanning,
      handleOkay,
      fetchPlanning,
      showPlanningTable,
      planningData,
      planninExist,
    }
  },
}
</script>