<template>
  <div class="bg-white radius-full py-4 px-5 my-4">
    <p class="font-sm fw-bold mb-3">School's source of power (by school level) in current academic class.</p>
    <div class="col-12 mt-3 mb-4">
      <label class="form-label font-sm fw-normal">
        Please select school level
      </label>
      <select class="form-select" required aria-label="Default select example" @change="levelChanged($event)" v-model="schoolLevel">
        <option value="">Select level</option>
        <option value="PRE_PRIMARY">Pre-Primary</option>
        <option value="PRIMARY">Primary</option>
        <option value="JUNIOR">Junior</option>
        <option value="SENIOR">Senior</option>
      </select>
    </div>
  </div>

  <div class="col-12 bg-white radius-full py-4 px-5 my-4">
    <label class="form-label font-sm fw-normal">
      What is this school's source of power?
    </label>
    <div class="col-12">
      <input
          type="text"
          class="form-control"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          :value="source"
          :disabled="moeUser === 'ROLE_MOE_ADMIN'"
      />
      <div
          class="dropdown-menu bg-accent shadow-sm w-100"
          aria-labelledby="featuresDropdown"
      >
        <div class="d-flex flex-column w-100">
          <div class="pe-2">
            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  v-model="sourceOfFacilityPower"
                  value="PHCN_NEPA"
                  id="phcn"
              />
              <label class="form-check-label" for="phcn">
                PHCN/NEPA
              </label>
            </div>
          </div>
          <div class="pe-2">
            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  v-model="sourceOfFacilityPower"
                  value="GENERATOR"
                  id="generator"
              />
              <label class="form-check-label" for="generator">
                Generator
              </label>
            </div>
          </div>
          <div class="pe-2">
            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  v-model="sourceOfFacilityPower"
                  value="SOLAR"
                  id="solar"
              />
              <label class="form-check-label" for="solar">
                Solar
              </label>
            </div>
          </div>
          <div class="pe-2">
            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  v-model="sourceOfFacilityPower"
                  value="NO_SOURCE_OF_POWER"
                  id="no-source-of-power"
              />
              <label class="form-check-label" for="no-source-of-power">
                No source of power
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
    <div v-if="moeUser !== 'ROLE_MOE_ADMIN'"  class="d-flex flex-column align-items-center justify-content-center mt-4">
      <button
         v-if="isFetched || showTitle == false "
          class="facilities-btn custom-btn font-tiny fw-bold"
          @click="updatePowerSource"
          :disabled="!isFetched"
      >
        Update
      </button>
      <button
          v-else
          class="facilities-btn custom-btn font-tiny fw-bold"
          @click="submitSharedFacilities"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import {mapActions, mapMutations, useStore} from "vuex";
import {ref, watch} from "vue";
import {computed} from "@vue/runtime-core";

export default {
  name: "PowerSource",
  emits: ['reload'],
  props:['showTitle', 'schoolInfo'],
  setup() {
    const store = useStore()
    const sourceOfFacilityPower = ref('')
    const powerSourceInfo = computed(() => store.state.questionnaireService.facilityPowerSourceInfo ? store.state.questionnaireService.facilityPowerSourceInfo[0] : store.state.questionnaireService.facilityPowerSourceInfo).value
    const source = ref('')

    const isFetched = ref(false)

    let schoolLevel = '', sourceOfPower = ''

    if (powerSourceInfo !== null) {
      ({schoolLevel, sourceOfPower} = powerSourceInfo)
      sourceOfFacilityPower.value = sourceOfPower
      isFetched.value = true
      if (sourceOfFacilityPower.value === "PHCN_NEPA") {
        source.value = sourceOfFacilityPower.value.replaceAll("_", "/")
      } else {
        source.value = _.capitalize(sourceOfFacilityPower.value).replaceAll("_", " ")
      }
    }

    watch(sourceOfFacilityPower, () => {
      if (sourceOfFacilityPower.value === "PHCN_NEPA") {
        source.value = sourceOfFacilityPower.value.replaceAll("_", "/")
      } else {
        source.value = _.capitalize(sourceOfFacilityPower.value).replaceAll("_", " ")
      }
    })
    const moeUser = computed(() => store.state.auth.user.role)
    return {
      moeUser,
      schoolLevel: schoolLevel,
      sourceOfFacilityPower,
      source,
      isFetched
    }
  },
  computed: {
    selectedSchoolInfo() {
      return this.schoolInfo ? this.schoolInfo : store.state.school.ownSchool
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitSourceOfPower', 'fetchFacilityPowerSource', 'updateFacilityPowerSource']),
    ...mapMutations(['setFacilityPowerSourceInfo']),

    async levelChanged(event) {
      if(!event.target.value) {
        this.$emit('reload')
        this.powerSourceInfo = {}
        return
      }
      const requestObject = {
        id: this.schoolInfo.id ? this.schoolInfo.id : this.$store.state.school.ownSchool.id,
        level: event.target.value
      }
      await this.fetchFacilityPowerSource(requestObject)
        .then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          })
          if (this.responseCode === 200) {
            this.$emit('reload')
          }
        })
    },

    async submitSharedFacilities() {
      const requestBody = {
        id: this.schoolInfo.id ? this.schoolInfo.id : this.$store.state.school.ownSchool.id,
        body: {
          sourceOfPower: this.sourceOfFacilityPower,
          schoolLevel: this.schoolLevel
        }
      }

      await this.submitSourceOfPower(requestBody)
        .then(() => {
          this.$notification.open({
            type: this.updateMessage.status,
            message: this.message,
          })
          if (this.responseCode === 201) {
            this.$emit('reload')
          }
        })
    },

    async updatePowerSource() {
      if (this.schoolLevel === '') {
        this.setQAMessage("Please select school level!")
      } else {
        const body = {
          sourceOfPower: this.sourceOfFacilityPower,
          schoolLevel: this.schoolLevel
        }
        const requestObject = {
          id: this.$store.state.questionnaireService.facilityPowerSourceInfo.id,
          body: body
        }
        await this.updateFacilityPowerSource(requestObject)
          .then(() => {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            if (this.responseCode === 204) {
              this.setFacilityPowerSourceInfo(null)
              this.$emit('reload')
            }
          })
      }
    }
  }
}
</script>

<style scoped>

</style>