<template>
  <div>
    <div class="bg-white radius-full py-4 px-5 my-4">
      <p class="font-sm fw-bold mb-3">Facilities registration (by school level) in current academic class.</p>
      <div class="col-12 mt-3 mb-3">
        <label class="form-label font-sm fw-normal">
          Please select school level
        </label>
        <select class="form-select" required aria-label="Default select example" @change="schoolLevelChanged($event)" v-model="schoolLevel">
          <option value="">Select level</option>
          <option value="PRE_PRIMARY">Pre-Primary</option>
          <option value="PRIMARY">Primary</option>
          <option value="JUNIOR">Junior</option>
          <option value="SENIOR">Senior</option>
        </select>
      </div>
    </div>

    <div class=" bg-white radius-full pb-5 mb-2">
      <section class="toilet px-5 py-4">
        <div class="d-flex flex-column px-3">`
          <section class="mb-5">
            <p class="font-sm fw-bold">Number of usable toilet units by each type of toilet.</p>
            <p class="font-sm fw-bold">Used only by students</p>
            <div class="d-flex mb-2">
              <div class="col-6"></div>
              <span class="col-2 text-center font-sm fw-normal">Only males</span>
              <span class="col-2 text-center font-sm fw-normal">Only female</span>
              <span class="col-2 text-center font-sm fw-normal">Mixed</span>
            </div>
            <div class="d-flex mb-3">
              <div class="col-6"><span class="font-sm fw-normal">Pit</span></div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.studentsMale.numberOfPit" type="number" aria-label="male">
              </div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.studentsFemale.numberOfPit" type="number" aria-label="female">
              </div>
              <div class="col-2">
                <input class="form-control outline" v-model="toiletData.studentsMixed.numberOfPit" type="number" aria-label="mixed">
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="col-6"><span class="font-sm fw-normal">Bucket system</span></div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.studentsMale.bucketSsystem" type="number" aria-label="male">
              </div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.studentsFemale.bucketSsystem" type="number" aria-label="female">
              </div>
              <div class="col-2">
                <input class="form-control outline" v-model="toiletData.studentsMixed.bucketSsystem" type="number" aria-label="mixed">
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="col-6"><span class="font-sm fw-normal">Water flush</span></div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.studentsMale.waterFlush" type="number" aria-label="male">
              </div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.studentsFemale.waterFlush" type="number" aria-label="female">
              </div>
              <div class="col-2">
                <input class="form-control outline" v-model="toiletData.studentsMixed.waterFlush" type="number" aria-label="mixed">
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="col-6"><span class="font-sm fw-normal">Others</span></div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.studentsMale.numberOfOther" type="number" aria-label="male">
              </div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.studentsFemale.numberOfOther" type="number" aria-label="female">
              </div>
              <div class="col-2">
                <input class="form-control outline" v-model="toiletData.studentsMixed.numberOfOther" type="number" aria-label="mixed">
              </div>
            </div>
          </section>
          <section class="mb-5">
            <p class="font-sm fw-bold">Used only by teachers</p>
            <div class="d-flex mb-2">
              <div class="col-6"></div>
              <span class="col-2 text-center font-sm fw-normal">Only males</span>
              <span class="col-2 text-center font-sm fw-normal">Only female</span>
              <span class="col-2 text-center font-sm fw-normal">Mixed</span>
            </div>
            <div class="d-flex mb-3">
              <div class="col-6"><span class="font-sm fw-normal">Pit</span></div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersMale.numberOfPit" type="number" aria-label="male">
              </div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersFemale.numberOfPit" type="number" aria-label="female">
              </div>
              <div class="col-2">
                <input class="form-control outline" v-model="toiletData.teachersMixed.numberOfPit" type="number" aria-label="mixed">
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="col-6"><span class="font-sm fw-normal">Bucket system</span></div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersMale.bucketSsystem" type="number" aria-label="male">
              </div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersFemale.bucketSsystem" type="number" aria-label="female">
              </div>
              <div class="col-2">
                <input class="form-control outline" v-model="toiletData.teachersMixed.bucketSsystem" type="number" aria-label="mixed">
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="col-6"><span class="font-sm fw-normal">Water flush</span></div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersMale.waterFlush" type="number" aria-label="male">
              </div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersFemale.waterFlush" type="number" aria-label="female">
              </div>
              <div class="col-2">
                <input class="form-control outline" v-model="toiletData.teachersMixed.waterFlush" type="number" aria-label="mixed">
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="col-6"><span class="font-sm fw-normal">Others</span></div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersMale.numberOfOther" type="number" aria-label="male">
              </div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersFemale.numberOfOther" type="number" aria-label="female">
              </div>
              <div class="col-2">
                <input class="form-control outline" v-model="toiletData.teachersMixed.numberOfOther" type="number" aria-label="mixed">
              </div>
            </div>
          </section>
          <section class="">
            <p class="font-sm fw-bold">Used by teachers and students</p>
            <div class="d-flex mb-2">
              <div class="col-6"></div>
              <span class="col-2 text-center font-sm fw-normal">Only males</span>
              <span class="col-2 text-center font-sm fw-normal">Only female</span>
              <span class="col-2 text-center font-sm fw-normal">Mixed</span>
            </div>
            <div class="d-flex mb-3">
              <div class="col-6"><span class="font-sm fw-normal">Pit</span></div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersAndStudentsMale.numberOfPit" type="number" aria-label="male">
              </div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersAndStudentsFemale.numberOfPit" type="number" aria-label="female">
              </div>
              <div class="col-2">
                <input class="form-control outline" v-model="toiletData.teachersAndStudentsMixed.numberOfPit" type="number" aria-label="mixed">
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="col-6"><span class="font-sm fw-normal">Bucket system</span></div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersAndStudentsMale.bucketSsystem" type="number" aria-label="male">
              </div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersAndStudentsFemale.bucketSsystem" type="number" aria-label="female">
              </div>
              <div class="col-2">
                <input class="form-control outline" v-model="toiletData.teachersAndStudentsMixed.bucketSsystem" type="number" aria-label="mixed">
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="col-6"><span class="font-sm fw-normal">Water flush</span></div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersAndStudentsMale.waterFlush" type="number" aria-label="male">
              </div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersAndStudentsFemale.waterFlush" type="number" aria-label="female">
              </div>
              <div class="col-2">
                <input class="form-control outline" v-model="toiletData.teachersAndStudentsMixed.waterFlush" type="number" aria-label="mixed">
              </div>
            </div>
            <div class="d-flex mb-3">
              <div class="col-6"><span class="font-sm fw-normal">Others</span></div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersAndStudentsMale.numberOfOther" type="number" aria-label="male">
              </div>
              <div class="col-2 me-2">
                <input class="form-control outline" v-model="toiletData.teachersAndStudentsFemale.numberOfOther" type="number" aria-label="female">
              </div>
              <div class="col-2">
                <input class="form-control outline" v-model="toiletData.teachersAndStudentsMixed.numberOfOther" type="number" aria-label="mixed">
              </div>
            </div>
          </section>
          <div class="d-flex justify-content-center">
            <button
                class="custom-btn font-tiny fw-bold"
                @click="submitForm"
                :disabled="!isFetched"
            >
              {{ isFetched || showTitle == false ? 'Update' : 'Save' }}
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import {ref} from "vue";

export default {
  props:['showTitle'],
  name: "ToiletsRegistration",
  setup() {
    const store = useStore()
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const toiletRegInfo = computed(() => store.state.questionnaireService.facilityToilets).value
    const schoolLevel = ref('')
    const isFetched = ref(false)

    let maleStudentsId = undefined, femaleStudentsId = undefined, mixedStudentsId = undefined,
        maleTeachersId = undefined, femaleTeachersId = undefined, mixedTeachersId = undefined,
        maleStudentsAndTeachersId = undefined, femaleStudentsAndTeachersId = undefined, mixedStudentsAndTeachersId = undefined

    let pitMaleStudents = '', bucketMaleStudents = '', waterFlushMaleStudents = '', otherMaleStudent = '',
        pitFemaleStudents = '', bucketFemaleStudents = '', waterFlushFemaleStudents = '', otherFemaleStudent = '',
        pitMixedStudents = '', bucketMixedStudents = '', waterFlushMixedStudents = '', otherMixedStudents = '',

        pitMaleTeachers = '', bucketMaleTeachers = '', waterFlushMaleTeachers = '', otherMaleTeachers = '',
        pitFemaleTeachers = '', bucketFemaleTeachers = '', waterFlushFemaleTeachers = '', otherFemaleTeachers = '',
        pitMixedTeachers = '', bucketMixedTeachers = '', waterFlushMixedTeachers = '', otherMixedTeachers = '',

        pitMaleStudentsAndTeachers = '', bucketMaleStudentsAndTeachers = '', waterFlushMaleStudentsAndTeachers = '', otherMaleStudentsAndTeachers = '',
        pitFemaleStudentsAndTeachers = '', bucketFemaleStudentsAndTeachers = '', waterFlushFemaleStudentsAndTeachers = '', otherFemaleStudentsAndTeachers = '',
        pitMixedStudentsAndTeachers = '', bucketMixedStudentsAndTeachers = '', waterFlushMixedStudentsAndTeachers = '', otherMixedStudentsAndTeachers = ''

    const useByPupilOrStudent = ["USED_ONLY_BY_STUDENTS", "USED_ONLY_BY_PUPILS"]
    const userByPupilOrStudentAndTeachers = ["USED_BY_TEACHERS_AND_STUDENTS", "USED_ONLY_BY_PUPILS_AND_TEACHERS"]

    if (toiletRegInfo !== null) {
      const onlyMaleStudents = Object.values(toiletRegInfo).find(toilet => useByPupilOrStudent.includes(toilet.useBy) && toilet.gender === "MALE")
      const onlyFemaleStudents = Object.values(toiletRegInfo).find(toilet => useByPupilOrStudent.includes(toilet.useBy) && toilet.gender === "FEMALE")
      const mixedStudents = Object.values(toiletRegInfo).find(toilet => useByPupilOrStudent.includes(toilet.useBy) && toilet.gender === "MIXED")

      const onlyMaleTeachers = Object.values(toiletRegInfo).find(toilet => toilet.useBy === "USED_ONLY_BY_TEACHERS" && toilet.gender === "MALE")
      const onlyFemaleTeachers = Object.values(toiletRegInfo).find(toilet => toilet.useBy === "USED_ONLY_BY_TEACHERS" && toilet.gender === "FEMALE")
      const mixedTeachers = Object.values(toiletRegInfo).find(toilet => toilet.useBy === "USED_ONLY_BY_TEACHERS" && toilet.gender === "MIXED")

      const onlyMaleStudentsAndTeachers = Object.values(toiletRegInfo).find(toilet => userByPupilOrStudentAndTeachers.includes(toilet.useBy) && toilet.gender === "MALE")
      const onlyFemaleStudentsAndTeachers = Object.values(toiletRegInfo).find(toilet => userByPupilOrStudentAndTeachers.includes(toilet.useBy) && toilet.gender === "FEMALE")
      const mixedStudentsAndTeachers = Object.values(toiletRegInfo).find(toilet => userByPupilOrStudentAndTeachers.includes(toilet.useBy) && toilet.gender === "MIXED")

      maleStudentsId = onlyMaleStudents.id
      pitMaleStudents = onlyMaleStudents.numberOfPit
      bucketMaleStudents = onlyMaleStudents.bucketSsystem
      waterFlushMaleStudents = onlyMaleStudents.waterFlush
      otherMaleStudent = onlyMaleStudents.numberOfOther

      femaleStudentsId = onlyFemaleStudents.id
      pitFemaleStudents = onlyFemaleStudents.numberOfPit
      bucketFemaleStudents = onlyFemaleStudents.bucketSsystem
      waterFlushFemaleStudents = onlyFemaleStudents.waterFlush
      otherFemaleStudent = onlyFemaleStudents.numberOfOther

      mixedStudentsId = mixedStudents.id
      pitMixedStudents = mixedStudents.numberOfPit
      bucketMixedStudents = mixedStudents.bucketSsystem
      waterFlushMixedStudents = mixedStudents.waterFlush
      otherMixedStudents = mixedStudents.numberOfOther

      maleTeachersId = onlyMaleTeachers.id
      pitMaleTeachers = onlyMaleTeachers.numberOfPit
      bucketMaleTeachers = onlyMaleTeachers.bucketSsystem
      waterFlushMaleTeachers = onlyMaleTeachers.waterFlush
      otherMaleTeachers = onlyMaleTeachers.numberOfOther

      femaleTeachersId = onlyFemaleTeachers.id
      pitFemaleTeachers = onlyFemaleTeachers.numberOfPit
      bucketFemaleTeachers = onlyFemaleTeachers.bucketSsystem
      waterFlushFemaleTeachers = onlyFemaleTeachers.waterFlush
      otherFemaleTeachers = onlyFemaleTeachers.numberOfOther

      mixedTeachersId = mixedTeachers.id
      pitMixedTeachers = mixedTeachers.numberOfPit
      bucketMixedTeachers = mixedTeachers.bucketSsystem
      waterFlushMixedTeachers = mixedTeachers.waterFlush
      otherMixedTeachers = mixedTeachers.numberOfOther

      maleStudentsAndTeachersId = onlyMaleStudentsAndTeachers.id
      pitMaleStudentsAndTeachers = onlyMaleStudentsAndTeachers.numberOfPit
      bucketMaleStudentsAndTeachers = onlyMaleStudentsAndTeachers.bucketSsystem
      waterFlushMaleStudentsAndTeachers = onlyMaleStudentsAndTeachers.waterFlush
      otherMaleStudentsAndTeachers = onlyMaleStudentsAndTeachers.numberOfOther

      femaleStudentsAndTeachersId = onlyFemaleStudentsAndTeachers.id
      pitFemaleStudentsAndTeachers = onlyFemaleStudentsAndTeachers.numberOfPit
      bucketFemaleStudentsAndTeachers = onlyFemaleStudentsAndTeachers.bucketSsystem
      waterFlushFemaleStudentsAndTeachers = onlyFemaleStudentsAndTeachers.waterFlush
      otherFemaleStudentsAndTeachers = onlyFemaleStudentsAndTeachers.numberOfOther

      mixedStudentsAndTeachersId = mixedStudentsAndTeachers.id
      pitMixedStudentsAndTeachers = mixedStudentsAndTeachers.numberOfPit
      bucketMixedStudentsAndTeachers = mixedStudentsAndTeachers.bucketSsystem
      waterFlushMixedStudentsAndTeachers = mixedStudentsAndTeachers.waterFlush
      otherMixedStudentsAndTeachers = mixedStudentsAndTeachers.numberOfOther

      schoolLevel.value = onlyMaleStudents.schoolLevel
      isFetched.value = true

    }

    const toiletData = {
      studentsMale: {
        id: maleStudentsId,
        useBy: 'USED_ONLY_BY_STUDENTS',
        numberOfPit: pitMaleStudents,
        bucketSsystem: bucketMaleStudents,
        waterFlush: waterFlushMaleStudents,
        numberOfOther: otherMaleStudent,
        gender: "MALE"
      },
      studentsFemale: {
        id: femaleStudentsId,
        useBy: 'USED_ONLY_BY_STUDENTS',
        numberOfPit: pitFemaleStudents,
        bucketSsystem: bucketFemaleStudents,
        waterFlush: waterFlushFemaleStudents,
        numberOfOther: otherFemaleStudent,
        gender: "FEMALE"
      },
      studentsMixed: {
        id: mixedStudentsId,
        useBy: 'USED_ONLY_BY_STUDENTS',
        numberOfPit: pitMixedStudents,
        bucketSsystem: bucketMixedStudents,
        waterFlush: waterFlushMixedStudents,
        numberOfOther: otherMixedStudents,
        gender: "MIXED"
      },

      teachersMale: {
        id: maleTeachersId,
        useBy: "USED_ONLY_BY_TEACHERS",
        numberOfPit: pitMaleTeachers,
        bucketSsystem: bucketMaleTeachers,
        waterFlush: waterFlushMaleTeachers,
        numberOfOther: otherMaleTeachers,
        gender: "MALE"
      },
      teachersFemale: {
        id: femaleTeachersId,
        useBy: "USED_ONLY_BY_TEACHERS",
        numberOfPit: pitFemaleTeachers,
        bucketSsystem: bucketFemaleTeachers,
        waterFlush: waterFlushFemaleTeachers,
        numberOfOther: otherFemaleTeachers,
        gender: "FEMALE"
      },
      teachersMixed: {
        id: mixedTeachersId,
        useBy: "USED_ONLY_BY_TEACHERS",
        numberOfPit: pitMixedTeachers,
        bucketSsystem: bucketMixedTeachers,
        waterFlush: waterFlushMixedTeachers,
        numberOfOther: otherMixedTeachers,
        gender: "MIXED"
      },

      teachersAndStudentsMale: {
        id: maleStudentsAndTeachersId,
        useBy: 'USED_BY_TEACHERS_AND_STUDENTS',
        numberOfPit: pitMaleStudentsAndTeachers,
        bucketSsystem: bucketMaleStudentsAndTeachers,
        waterFlush: waterFlushMaleStudentsAndTeachers,
        numberOfOther: otherMaleStudentsAndTeachers,
        gender: "MALE"
      },
      teachersAndStudentsFemale: {
        id: femaleStudentsAndTeachersId,
        useBy: 'USED_BY_TEACHERS_AND_STUDENTS',
        numberOfPit: pitFemaleStudentsAndTeachers,
        bucketSsystem: bucketFemaleStudentsAndTeachers,
        waterFlush: waterFlushFemaleStudentsAndTeachers,
        numberOfOther: otherFemaleStudentsAndTeachers,
        gender: "FEMALE"
      },
      teachersAndStudentsMixed: {
        id: mixedStudentsAndTeachersId,
        useBy: 'USED_BY_TEACHERS_AND_STUDENTS',
        numberOfPit: pitMixedStudentsAndTeachers,
        bucketSsystem: bucketMixedStudentsAndTeachers,
        waterFlush: waterFlushMixedStudentsAndTeachers,
        numberOfOther: otherMixedStudentsAndTeachers,
        gender: "MIXED"
      }
    }

    return {
      schoolInfo,
      schoolLevel,
      toiletData,
      isFetched,
    }
  },
  computed: {
    responseCode() { return this.$store.state.questionnaireService.responseCode },
  },
  methods: {
    ...mapActions(['submitFacilityToilets', 'fetchToiletFacility', 'updateToiletFacilitySeaters']),
    ...mapMutations(['setQAMessage', 'setFacilityToilets']),

    async schoolLevelChanged(event) {
      const requestObject = {
        id: this.schoolInfo.id,
        level: event.target.value
      }
      await this.fetchToiletFacility(requestObject)
        .then(() => {
          if (this.$store.state.questionnaireService.facilityToilets !== null) {
            this.$emit('reload')
          }
        })
      if (event.target.value === 'PRE_PRIMARY' || event.target.value === "PRIMARY") {
        Object.values(this.toiletData).map((data) => {
          if (data.useBy === 'USED_ONLY_BY_STUDENTS') {
            data.useBy = 'USED_ONLY_BY_PUPILS'
          } else if (data.useBy === 'USED_BY_TEACHERS_AND_STUDENTS') {
            data.useBy = 'USED_ONLY_BY_PUPILS_AND_TEACHERS'
          }
        })
      }
    },

    async submitForm() {
      if (this.schoolLevel === '') {
        this.setQAMessage("Please select school level!")
        return
      }

      let toiletData = Array.from(Object.values(this.toiletData))

      const requestObject = {
        id: this.schoolInfo.id,
        level: this.schoolLevel,
        body: toiletData
      }

      if (this.isFetched) {
        await this.updateToiletFacilitySeaters(toiletData)
          .then(() => {
            if (this.responseCode === 204 || this.responseCode === 200) {
              this.setFacilityToilets(null)
              this.$emit('reload')
            }
          })
      } else {
        await this.submitFacilityToilets(requestObject)
          .then(() => {
            if (this.responseCode === 201) {
              this.$emit('reload')
            }
      })
      }
    }
  }
}
</script>

<style scoped>

</style>