<template>
  <div>
    <p class="font-md fw-bold mt-2 mb-4">Student/Teacher Books</p>
    <vocational-and-science v-if="isScienceOrVocational" />
    <regular-schools v-else />

    <div class="d-flex flex-column align-items-center justify-content-center">
      <span 
        @click="$emit('saveBook')" 
        class="font-sm dm-color my-3"
      >
        Continue
      </span>
    </div>

    <transition name="modal">
      <modal v-if="hasMessage" @close="closeModal">
        <template v-slot:body>
          <p>{{ message }}</p>
        </template>
      </modal>
    </transition>
  </div>
</template>

<script>
import {mapMutations, useStore} from 'vuex'
import {computed} from "@vue/runtime-core";
import RegularSchools from './book/RegularSchools.vue';
import VocationalAndScience from './book/VocationalAndScience.vue';
import {onMounted} from "vue";
import Modal from "@/helpers/utils/Modal";

export default {
  components: { RegularSchools, VocationalAndScience, Modal },
  name: "Book",
  setup() {
    const store = useStore()
    onMounted(() => {
      store.dispatch('fetchMySchool')
    })
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const specials = ["VOCATION_TRAINING", "SCIENCE_TECHNOLOGY", "SCIENCE_TECHNOLOGY_VOCATIONAL"]
    const isScienceOrVocational = specials.includes(schoolInfo.schoolType)
  
    return {
      isScienceOrVocational,
    }
  },
  computed: {
    message() {
      return this.$store.state.questionnaireService.message
    },

    hasMessage() {
      return this.message !== null
    },
  },
  methods: {
    ...mapMutations(['setQAMessage', 'setResponseCode']),

    closeModal() {
      this.setQAMessage(null)
      this.setResponseCode(null)
    }
  }
}
</script>

<style scoped>

</style>