<template>
  <div class="bg-white radius-full py-4 px-5 my-4">
    <p class="font-sm fw-bold mb-3">School's fence condition (by school level) in current academic class.</p>
    <div class="col-12 mt-3 mb-4">
      <label class="form-label font-sm fw-normal">
        Please select school level
      </label>
      <select class="form-select" required aria-label="Default select example" @change="levelChanged($event)" v-model="schoolLevel">
        <option value="">Select level</option>
        <option value="PRE_PRIMARY">Pre-Primary</option>
        <option value="PRIMARY">Primary</option>
        <option value="JUNIOR">Junior</option>
        <option value="SENIOR">Senior</option>
      </select>
    </div>
  </div>

  <div class="row bg-white radius-full py-4 px-5 my-4">
    <div class="col-12 mt-3 px-3">
      <label class="form-label font-sm fw-normal">
        What is the condition of the fence/wall?
      </label>
      <div class="col-12">
        <input
            type="text"
            class="form-control"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :value="formattedInfo"
            :disabled="moeUser === 'ROLE_MOE_ADMIN'"
        />
        <div
            class="dropdown-menu bg-accent shadow-sm w-100"
            aria-labelledby="featuresDropdown"
        >
          <div class="d-flex flex-column w-100">
            <div class="pe-2">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    v-model="facilityFenceInfo"
                    value="IN_GOOD_CONDITION"
                    id="good"
                />
                <label class="form-check-label" for="good">
                  In good condition
                </label>
              </div>
            </div>
            <div class="pe-2">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    v-model="facilityFenceInfo"
                    value="NEED_MINOR_REPAIRS"
                    id="minor-repair"
                />
                <label class="form-check-label" for="minor-repair">
                  Minor repair
                </label>
              </div>
            </div>
            <div class="pe-2">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    v-model="facilityFenceInfo"
                    value="NEED_MAJOR_REPAIRS"
                    id="major-repair"
                />
                <label class="form-check-label" for="major-repair">
                  Needs major repair
                </label>
              </div>
            </div>
            <div class="pe-2">
              <div class="form-check">
                <input
                    class="form-check-input"
                    type="radio"
                    v-model="facilityFenceInfo"
                    value="NO_FENCE_OR_WALL"
                    id="no-fence"
                />
                <label class="form-check-label" for="no-fence">
                  No fence or wall
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
    <br/>
    <br/>
    <div v-if="moeUser !== 'ROLE_MOE_ADMIN'" class="d-flex flex-column align-items-center justify-content-center mt-4">
      <button
          class="facilities-btn custom-btn font-tiny fw-bold"
          @click="submitSharedFacilities"
          :disabled="!isFetched"
      >
        {{ isFetched || showTitle == false ? 'Update' : 'Save'  }}
      </button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import {ref, watch} from "vue";

export default {
  name: "FenceStructure",
  emits: ['reload'],
  props: ['showTitle', 'schoolInfo'],
  setup() {
    const store = useStore()
    const fenceInfo = computed(() => store.state.questionnaireService.facilityFence ? store.state.questionnaireService.facilityFence[0] : store.state.questionnaireService.facilityFence).value
    console.log(fenceInfo)
    const facilityFenceInfo = ref('')
    const formattedInfo = ref('')
    const isFetched = ref(false)
    let schoolLevel = '', condition = ''
    const moeUser = computed(() => store.state.auth.user.role)
    if (fenceInfo !== null) {
      ({schoolLevel, condition} = fenceInfo)
      facilityFenceInfo.value = condition
      formattedInfo.value = _.capitalize(facilityFenceInfo.value).replaceAll("_", " ")
      isFetched.value = true
    }

    watch(facilityFenceInfo, () => {
      formattedInfo.value = _.capitalize(facilityFenceInfo.value).replaceAll("_", " ")
    })

    return {
      moeUser,
      schoolLevel: schoolLevel,
      facilityFenceInfo,
      formattedInfo,
      isFetched,
    }
  },
  computed: {
    schoolInfo() {
      return this.$store.state.school.ownSchool
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitFacilityFence', 'fetchFacilityFence', 'updateFacilityFence']),
    ...mapMutations(['setFacilityFence', 'setQAMessage']),

    levelChanged(event) {
     if(!event.target.value) return
      const requestObject = {
        id: this.schoolInfo.id ? this.schoolInfo.id : this.$store.state.school.ownSchool.id,
        level: event.target.value
      }
      this.fetchFacilityFence(requestObject)
          .then(() => {
            this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        })
            if (this.responseCode === 200) {
              this.$emit('reload')
            }
          })
    },

    async submitSharedFacilities() {
      if (this.schoolLevel === '') {
        this.setQAMessage("Please select school level!")
      } else {
        const requestBody = {
          id: this.schoolInfo.id,
          body: {
            condition: this.facilityFenceInfo,
            schoolLevel: this.schoolLevel
          }
        }

        if (this.isFetched) {
          const requestBodyToupdate = {
          id: this.$store.state.questionnaireService.facilityFence.id,
          body: {
            condition: this.facilityFenceInfo,
            schoolLevel: this.schoolLevel
          }
        }
          await this.updateFacilityFence(requestBodyToupdate)
            .then(() => {
              this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        })
              if (this.responseCode === 204) {
                this.$emit('reload')
                this.setFacilityFence(null)
              }
            })
        } else {
          await this.submitFacilityFence(requestBody)
            .then(() => {
              this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        })
              if (this.responseCode === 201) {
                this.$emit('reload')
              }
            })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>