<template>
  <div>
    <p v-if="!hideTitle" class="font-md fw-bold my-4">School Identification</p>
    <form @submit.prevent="saveIdentification" method="post">
      <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
        <div class="row justify-content-between px-2 pb-4">
          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> School Code </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter school code"
              required
              :disabled="disableField"
              v-model="schoolInfo.schoolCode"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Year of establishment
            </label>
            <input
                type="number"
                min="1900"
                class="form-control"
                placeholder="Enter year of establishment"
                :disabled="disableField"
                v-model="schoolInfo.yearOfEstablishment"
            />
          </div>

          <div class="col-4 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              School coordinates
            </label>
            <input
              type="text"
              step="0.0000000001"
              class="form-control"
              placeholder="Elevation"
              :disabled="disableField"
              v-model="schoolInfo.elevation"
            />
          </div>

          <div class="col-4 mt-5 px-3">
            <input
              type="number"
              step="0.0000000001"
              class="form-control"
              placeholder="Longitude"
              :disabled="disableField"
              v-model="schoolInfo.longitude"
            />
          </div>

          <div class="col-4 mt-5 px-3">
            <input
              type="number"
              step="0.0000000001"
              class="form-control"
              placeholder="Latitude"
              :disabled="disableField"
              v-model="schoolInfo.latitude"
            />
          </div>

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> School name </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter school name"
              required
              :disabled="disableField"
              v-model="schoolInfo.schoolName"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Street name </label>
            <input
              type="text"
              class="form-control"
              placeholder="Street name"
              :disabled="disableField"
              v-model="schoolInfo.streetName"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Street number </label>
            <input
              type="text"
              class="form-control"
              placeholder="Street number"
              :disabled="disableField"
              v-model="schoolInfo.streetNumber"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              School type
            </label>
            <select class="form-select" aria-label="Default select example" v-model="schoolInfo.schoolType" :disabled="disableField">
              <option v-if="schoolInfo.schoolType" :value="schoolInfo.schoolType.toUpperCase()" selected> {{ lodash.capitalize(schoolInfo.schoolType) }} </option>
              <option v-else selected>School category</option>
              <option value="REGULAR">Regular</option>
              <option value="ISLAMIYYA">Islamiyya</option>
              <option value="NOMADIC">Nomadic</option>
              <option value="SPECIAL">Special needs</option>
              <option value="SCIENCE_TECHNOLOGY">Science and technology</option>
              <option value="VOCATION_TRAINING">Vocational training</option>
              <option value="SCIENCE_TECHNOLOGY_VOCATIONAL">Science, technology and vocational</option>
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Location </label>
            <select class="form-select" v-model="schoolInfo.location" :disabled="disableField">
              <option v-if="schoolInfo.location" :value="schoolInfo.location" selected> {{ lodash.capitalize(schoolInfo.location) }} </option>
              <option v-else selected="">School location</option>
              <option value="RURAL">Rural</option>
              <option value="URBAN">Urban</option>
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Name of village/town
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Name of village/town"
              :disabled="disableField"
              v-model="schoolInfo.villageOrTown"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> School classification </label>
            <select class="form-select" aria-label="Default select example" :disabled="disableField" v-model="schoolInfo.classification">
              <option v-if="schoolInfo.classification" :value="schoolInfo.classification" selected>
                {{ lodash.capitalize(schoolInfo.classification) }}
              </option>
              <option v-else selected>School classification</option>
              <option value="PUBLIC" selected>Public</option>
              <option value="PRIVATE">Private</option>
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Local government area
            </label>
            <select class="form-select" v-model="schoolInfo.lga" :disabled="disableField" required>
              <option v-if="schoolInfo.lga" :value="schoolInfo.lga" selected> {{ lodash.capitalize(schoolInfo.lga) }} </option>
              <option v-else selected>Select LGA</option>
              <option v-for="(lg, index) in lgs" :key="index" :value="lg.name.toUpperCase()">
                {{ lg.name }}
              </option>
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              School telephone
            </label>
            <input
              type="tel"
              class="form-control"
              placeholder="+234"
              required
              :disabled="disableField"
              v-model="schoolInfo.schoolTelephone"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Ward </label>
            <select class="form-select" v-model="schoolInfo.ward" required :disabled="disableField">
              <option selected>Select Ward</option>
              <option v-for="(ward, index) in wards" :key="index" :value="ward.toUpperCase()">
                {{ lodash.capitalize(ward) }}
              </option>
            </select>
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              School email address
            </label>
            <input
              type="email"
              class="form-control"
              placeholder="Enter school email"
              :disabled="disableField"
              v-model="schoolInfo.email"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Distance from LGA (in km)
            </label>
            <input
              type="number"
              step="0.0000000001"
              class="form-control"
              placeholder="Enter distance from LGA (in km)"
              :disabled="disableField"
              v-model="schoolInfo.distanceFromLGA"
            />
          </div>

          <div class="col-6 mt-3 px-3">
            <label class="form-label font-sm fw-normal"> Ownership </label>
            <select class="form-select" v-model="schoolInfo.ownership" required :disabled="disableField">
              <option value="">School ownership</option>
              <option value="FEDERAL">Federal government</option>
              <option value="STATE">State government</option>
              <option value="LGEA">Local government</option>
              <option value="PRIVATE">Private</option>
              <option value="NGO">Non-governmental organization</option>
              <option value="FAITH_BASE">Faith-base organization</option>
              <option value="COMMUNITY">Community</option>
              <option value="INDIVIDUAL">Individual</option>
              <option value="CORPORATION">Corporation</option>
              <option value="OTHERS">Others</option>
            </select>
          </div>

          <div v-if="isPrivate" class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Is member of private schools association
            </label>
            <div class="d-flex">
              <div class="col-6 pe-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="preparesPTA"
                    value="yes"
                    :disabled="disableField"
                    v-model="
                      schoolInfo.isTheSchoolMemberOfPrivateSchoolsAssociation
                    "
                    id="yesIsMember"
                  />
                  <label class="form-check-label font-sm" for="yesIsMember">
                    Yes
                  </label>
                </div>
              </div>
              <div class="col-6 ps-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="preparesPTA"
                    value="no"
                    :disabled="disableField"
                    v-model="
                      schoolInfo.isTheSchoolMemberOfPrivateSchoolsAssociation
                    "
                    id="notMember"
                  />
                  <label class="form-check-label font-sm" for="notMember">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="belongToAssociation" class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Name of private school association or none
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter name of association."
              :disabled="disableField"
              required
              v-model="schoolInfo.nameOfPrivateSchoolAssociationOrNone"
            />
          </div>

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              Average distance from catchment communities (in km)
            </label>
            <input
              type="number"
              step="0.0000000001"
              class="form-control"
              placeholder="Avg distance from catchment (in km)"
              :disabled="disableField"
              v-model="schoolInfo.avgDistanceFromCatchment"
            />
          </div>

          <div class="col-12 mt-3 px-3">
            <label class="form-label font-sm fw-normal">
              School mailing address
            </label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter mailing address"
              :disabled="disableField"
              v-model="schoolInfo.mailing"
            />
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-column align-items-center justify-content-center"
      >
        <input
          type="submit"
          class="custom-btn font-tiny fw-bold"
          value="Continue"
        />
      </div>
    </form>
  </div>
</template>

<script>
import lodash from "lodash"
import avatar from "@/assets/images/emptyAvatar.png";
import stateNLG from "@/helpers/utils/state_local.json";
import lgaWards from "@/helpers/utils/wards_by_lga.json";
import { mapMutations, useStore } from "vuex";
import { computed, watch, reactive, toRefs } from "@vue/runtime-core";
import { onMounted, ref } from 'vue';
import dataCollectionMixin from "@/helpers/mixins/dataCollectionMixin";
// import Modal from "@/helpers/utils/Modal";

export default {
  name: "SchoolIdentification",
  emits: ['saveIdentification'],
  mixins: [dataCollectionMixin],
  props: ['operation'],
  // components: {Modal},
  setup(props) {
    const store = useStore();    
    const { operation } = toRefs(props)
    const disableField = ref(false)
    const hideTitle = ref(true)
    onMounted(() => {
      if (operation.value !== undefined && operation.value.toLowerCase() === 'view') {
        disableField.value = true
      } else if (operation.value !== undefined && operation.value.toLowerCase() === 'edit') {
        console.log(operation.value)
      } else{
        hideTitle.value = false
        store.dispatch('fetchMySchool')
      }
    })  
    const schoolIdentification = computed(() => store.state.questionnaire.identificationData).value;
    const mySchoolInfo = computed(() => store.state.school.ownSchool).value
    const info = (schoolIdentification !== null) ? schoolIdentification : mySchoolInfo
    let schoolCode = "", elevation = "", schoolName = "", lga = "", ward = "", streetNumber = "", schoolType = ""
    let streetName = "", email = "", location = "", schoolTelephone = "", villageOrTown = "", longitude = "", latitude = ""
    let classification = ""

    if (info !== null) {
      ({
          schoolCode, elevation, schoolName, lga, ward, streetName, streetNumber, email,
          schoolTelephone, villageOrTown, longitude, latitude, location, schoolType, classification
        } = info
      )
    }

    const dummyAvatar = avatar;
    const state = Object.entries(stateNLG)[35];
    const stateName = Object.values(state[1])[0].split(" ")[0]
    const lgs = Object.values(state[1].locals);
    const wards = ref([])

    const schoolInfo = reactive({
          schoolCode: schoolCode,
          yearOfEstablishment: "",
          elevation: elevation,
          schoolName: schoolName,
          streetNumber: streetNumber,
          schoolType: schoolType,
          classification: classification,
          location: location,
          lga: lga,
          ward: ward,
          streetName: streetName,
          email: email,
          schoolTelephone: schoolTelephone,
          villageOrTown: villageOrTown,
          longitude: longitude,
          latitude: latitude,
          avgDistanceFromCatchment: "",
          distanceFromLGA: "",
          ownership: "",
          isTheSchoolMemberOfPrivateSchoolsAssociation: "",
          nameOfPrivateSchoolAssociationOrNone: "",
    });

    const updateLevelOfEducation = () => {
      schoolInfo.levelsOffered = ["junior secondary", "senior secondary"]
    }

    watch(
      () => schoolInfo.classification,
      (category) => {
        const specials = ["vocational training center", "science and tech"]
        if (specials.includes(category)) {                    
          updateLevelOfEducation()
        }
      }
    )

    watch(
      () => schoolInfo.lga, 
      (lga) => {        
        for (const state in lgaWards) {        
          if (stateName.toLowerCase() === Object.keys(lgaWards[state])[0].toLowerCase()) {
            const lgaEntries = Object.entries(lgaWards[state])[0]
            const lgsAndWards = Object.values(lgaEntries)[1]
            const lgs = Object.keys(lgsAndWards)        
            for (const [index, lg] of lgs.entries()) {
              if (lg.toLowerCase() === lga.toLowerCase()) {
                wards.value = Object.values(lgsAndWards)[index];                    
              }
            }
          }
        }
      }
    )

    return {
      dummyAvatar,
      lgs,
      schoolInfo,      
      lodash,
      mySchoolInfo,
      wards,
      disableField,
      hideTitle,
    };
  },
  computed: {
    isPrivate() {
      return this.schoolInfo.schoolType === "private";
    },

    isRegularSchool() {
      const specials = ["vocational training center", "science and tech"]
      return !specials.includes(this.schoolInfo.classification)
    },

    belongToAssociation() {
      return (
        this.schoolInfo.schoolType === "private" &&
        this.schoolInfo.isTheSchoolMemberOfPrivateSchoolsAssociation === "yes"
      );
    },

    message() {
      return this.$store.state.questionnaireService.message
    },

    hasMessage() {
      return this.message !== null
    }
  },
  methods: {
    ...mapMutations(["setIdentificationData", "setIdentificationDone", "setSchoolType", "setResponseCode", "setQAMessage"]),

    clearData() {
      this.setIdentificationData(null);
    },

    levelToggle(e) {
      console.log(e.target.value)
    },

    saveIdentification() {
      const body = {
        schoolCode: this.schoolInfo.schoolCode,
        yearOfEstablishment: parseInt(this.schoolInfo.yearOfEstablishment),
        elevation: parseInt(this.schoolInfo.elevation),
        schoolName: this.schoolInfo.schoolName,
        streetNumber: this.schoolInfo.streetNumber,
        schoolType: this.schoolInfo.schoolType.toUpperCase(),
        classification: this.schoolInfo.classification.toUpperCase(),
        location: this.schoolInfo.location.toUpperCase(),
        lga: this.schoolInfo.lga.toUpperCase(),
        ward: this.schoolInfo.ward,
        streetName: this.schoolInfo.streetName,
        email: this.schoolInfo.email,
        schoolTelephone: this.schoolInfo.schoolTelephone,
        villageOrTown: this.schoolInfo.villageOrTown,
        longitude: parseInt(this.schoolInfo.longitude),
        latitude: parseInt(this.schoolInfo.latitude),
        avgDistanceFromCatchment: parseInt(this.schoolInfo.avgDistanceFromCatchment),
        distanceFromLGA: parseInt(this.schoolInfo.distanceFromLGA),
        ownership: this.schoolInfo.ownership.toUpperCase(),
        isTheSchoolMemberOfPrivateSchoolsAssociation: this.schoolInfo.isTheSchoolMemberOfPrivateSchoolsAssociation,
        nameOfPrivateSchoolAssociationOrNone: this.schoolInfo.nameOfPrivateSchoolAssociationOrNone,
      }
      this.setSchoolType(this.schoolInfo.schoolType)
      this.setIdentificationData(body);
      const requestBody = {
        id: this.mySchoolInfo.id,
        body: body
      }
      this.$emit('saveIdentification', requestBody)
      
    },

    checkLevelsOffered() {
      return this.schoolInfo.levelsOffered.length !== 0;
    },

    closeModal() {
      this.setQAMessage(null)
    }
  },
};
</script>
