<template>
  <div>
    <p v-if="!hideTitle" class="font-md fw-bold my-4">School Characteristics</p>
    <div class="d-flex flex-column px-5 py-4 mb-4 radius-full bg-white">
      <div class="row justify-content-between px-2 pb-4">
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Does this school operate shift system?
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="hasShiftSystem" value="true" v-model="characteristicsInfo.hasShiftSystem" id="yesHasShift">
                <label class="form-check-label font-sm" for="yesHasShift">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input class="form-check-input" type="radio"  :disabled="disableField" name="hasShiftSystem" value="false" v-model="characteristicsInfo.hasShiftSystem" id="noShift">
                <label class="form-check-label font-sm" for="noShift">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Does this school share facilities with other schools(s)?
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="shareFacilities" value="true" v-model="characteristicsInfo.sharesFacilities" id="yesShares">
                <label class="form-check-label font-sm" for="yesShares">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="shareFacilities" value="false" v-model="characteristicsInfo.sharesFacilities" id="notShares">
                <label class="form-check-label font-sm" for="notShares">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Does any teacher teach more than one class at a time?
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="teachMany" value="true" v-model="characteristicsInfo.teachMany" id="yesTeach">
                <label class="form-check-label font-sm" for="yesTeach">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="teachMany" value="false" v-model="characteristicsInfo.teachMany" id="notTeach">
                <label class="form-check-label font-sm" for="notTeach">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            What is the average distance of this school from its catchment area (in km)?
          </label>
          <input
            type="number"
            step="0.0000000001"
            class="form-control"
            placeholder="Enter 0 if within 1km"
            v-model="characteristicsInfo.avgDistanceFromCatchment"
          />
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            How far is the school from the LGA HQ (in km)?
          </label>
          <input
            type="number"
            step="0.0000000001"
            class="form-control"
            placeholder="Enter 0 if within 1km"
            v-model="characteristicsInfo.distanceFromLGA"
          />
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            How many pupils live farther than 3km from the school?
          </label>
          <input
            type="number"
            class="form-control"
            placeholder="Enter number of pupils that live farther than 3km"
            v-model="characteristicsInfo.numberOfPupilsFartherThanThreeKm"
          />
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            How many pupils board at the school premises?
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <input
                type="number"
                class="form-control"
                placeholder="Female"
                v-model="characteristicsInfo.numberOfBoardersFemale"
              />
            </div>
            <div class="col-6 ps-3">
              <input
                type="number"
                class="form-control"
                placeholder="Male"
                v-model="characteristicsInfo.numberOfBoardersMale"
              />
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Does the school prepare an SDP in the last school year?
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="preparesSDP" value="true" v-model="characteristicsInfo.preparedSDPLastYear" id="yesPrepares">
                <label class="form-check-label font-sm" for="yesPrepares">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="preparesSDP" value="false" v-model="characteristicsInfo.preparedSDPLastYear" id="noPrepare">
                <label class="form-check-label font-sm" for="noPrepare">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Does the school prepare an SBMC, which meets at least once a year?
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="preparesSBMC" value="true" v-model="characteristicsInfo.preparesSBMC" id="yesPreparesSBMC">
                <label class="form-check-label font-sm" for="yesPreparesSBMC">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="preparesSBMC" value="false" v-model="characteristicsInfo.preparesSBMC" id="noPrepareSBMC">
                <label class="form-check-label font-sm" for="noPrepareSBMC">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Does the school have a PTA/PF/MA, which meets at least once a year?
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="preparesPTA" value="true" v-model="characteristicsInfo.hasPTA_PF_MA" id="yesPreparesPTA">
                <label class="form-check-label font-sm" for="yesPreparesPTA">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="preparesPTA" value="true" v-model="characteristicsInfo.hasPTA_PF_MA" id="noPreparePTA">
                <label class="form-check-label font-sm" for="noPreparePTA">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Date of last inspection visit
          </label>
          <input
            type="date"
            class="form-control"
            placeholder="Member of private school association"
            v-model="characteristicsInfo.lastInspectionDate"
          />
        </div>
        <div class="col-6 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Number of inspection in the past year?
          </label>
          <input
            type="number"
            class="form-control"
            placeholder="Enter number of inspection"
            v-model="characteristicsInfo.noOfInspectionInLastYear"
          />
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Which authority conducted the last inspection
          </label>
          <div class="d-flex">
            <div class="col-4 pe-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="inspectionAuthority" value="FG" v-model="characteristicsInfo.inspectionAuthority" id="federal">
                <label class="form-check-label font-sm" for="federal">
                  Federal
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="inspectionAuthority" value="STATE" v-model="characteristicsInfo.inspectionAuthority" id="state">
                <label class="form-check-label font-sm" for="state">
                  State
                </label>
              </div>
            </div>
            <div class="col-4 ps-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="inspectionAuthority" value="LGEA" v-model="characteristicsInfo.inspectionAuthority" id="local">
                <label class="form-check-label font-sm" for="local">
                  LGEA
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            How many pupils benefited from Conditional Cash transfer?
          </label>
          <input
            type="number"
            class="form-control"
            placeholder="Enter number of pupils"
            v-model="characteristicsInfo.noOfCashTransferBeneficiary"
          />
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Has your school received grants in the last academic year?
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="receivedGrants" value="true" v-model="characteristicsInfo.receivedGrantLastYear" id="yesReceived">
                <label class="form-check-label font-sm" for="yesReceived">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="receivedGrants" value="false" v-model="characteristicsInfo.receivedGrantLastYear" id="notReceived">
                <label class="form-check-label font-sm" for="notReceived">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 px-3">
          <label class="form-label font-sm fw-normal">
            Does the school have a security guard?
          </label>
          <div class="d-flex">
            <div class="col-6 pe-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="hasSecurityGuards" value="true" v-model="characteristicsInfo.hasSecurityGuard" id="yesGuards">
                <label class="form-check-label font-sm" for="yesGuards">
                  Yes
                </label>
              </div>
            </div>
            <div class="col-6 ps-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" checked :disabled="disableField" name="hasSecurityGuards" value="false" v-model="characteristicsInfo.hasSecurityGuard" id="noGuards">
                <label class="form-check-label font-sm" for="noGuards">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
      <button
        v-if="hideTitle"
          class="custom-btn font-tiny fw-bold"
          @click="disableField = !disableField"
          
      >
        Edit
      </button>
       |  
       <button
          class="custom-btn font-tiny fw-bold"
          @click="saveCharacteristics"
      >
        Continue
      </button>
    </div>

    <transition name="modal">
      <modal v-if="hasMessage" @close="closeModal">
        <template v-slot:body>
          <p>{{ message }}</p>
        </template>
      </modal>
    </transition>
  </div>
</template>

<script>
import {useStore, mapMutations} from "vuex";
import {computed, watch, reactive, toRefs} from "@vue/runtime-core";
import {onMounted, ref} from "vue";
import Modal from "@/helpers/utils/Modal";

export default {
  name: "SchoolCharacteristics",
  components: {Modal},
  props: ['operation'],
  setup(props) {
    const store = useStore()
    const { operation } = toRefs(props)
    const disableField = ref(false)
    const hideTitle = ref(true)
    onMounted(() => {
      if (operation.value !== undefined && operation.value.toLowerCase() === 'view') {
        disableField.value = true
      } else if (operation.value !== undefined && operation.value.toLowerCase() === 'edit') {
        console.log(operation.value)
      } else{
        hideTitle.value = false
        store.dispatch('fetchMySchool')
      }
    })
    const schoolInfo = computed(() => store.state.school.ownSchool).value
    const schoolCharacteristics = computed(() => store.state.questionnaire.characteristicsData).value
    const characteristicsInfo = schoolCharacteristics ? schoolCharacteristics : {
      school: schoolInfo.id,
      schoolCode: schoolInfo.schoolCode,
      lga: schoolInfo.lga,
      hasShiftSystem: "",
      sharesFacilities: "",
      teachMany: "",
      avgDistanceFromCatchment: "",
      distanceFromLGA: "",
      numberOfPupilsFartherThanThreeKm: "",
      numberOfBoardersFemale: "",
      numberOfBoardersMale: "",
      preparedSDPLastYear: "",
      preparesSBMC: "",
      hasPTA_PF_MA: "",
      lastInspectionDate: "",
      noOfInspectionInLastYear: "",
      inspectionAuthority: "",
      noOfCashTransferBeneficiary: "",
      receivedGrantLastYear: "",
      hasSecurityGuard: "",
      ownership: "",
      recognitionStatus: "",
    }
    return {
      characteristicsInfo,
      schoolInfo,
      disableField,
      hideTitle
    }
  },
  computed: {
    message() {
      return this.$store.state.questionnaireService.message
    },

    hasMessage() {
      return this.message !== null
    }
  },
  methods: {
    ...mapMutations(['setCharacteristicsData', 'setQAMessage', 'setResponseCode']),

    saveCharacteristics() {
      this.setCharacteristicsData(this.characteristicsInfo)
      this.$emit('saveCharacteristics', this.characteristicsInfo)
    },

    closeModal() {
      this.setQAMessage(null)
      this.setResponseCode(null)
    }
  }
}
</script>

<style scoped>

</style>