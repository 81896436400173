<template>
  <div>
    <section class="mb-5">
      <p class="font-sm fw-bold">Primary enrolment by age for the current school year</p>
      <div class="d-flex mb-2">
        <div class="col-3"></div>
        <span class="col me-1 text-center font-sm fw-normal">Primary 1</span>
        <span class="col me-1 text-center font-sm fw-normal">Primary 2</span>
        <span class="col me-1 text-center font-sm fw-normal">Primary 3</span>
        <span class="col me-1 text-center font-sm fw-normal">Primary 4</span>
        <span class="col me-1 text-center font-sm fw-normal">Primary 5</span>
        <span class="col me-1 text-center font-sm fw-normal">Primary 6</span>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Number of streams</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="primary1Streams.numberOfStream" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="primary2Streams.numberOfStream" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="primary3Streams.numberOfStream" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="primary4Streams.numberOfStream" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="primary5Streams.numberOfStream" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="primary6Streams.numberOfStream" type="number" aria-label="female">
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="col-3"><span class="font-sm fw-normal">Number of streams with multi-grade teaching</span></div>
        <div class="col me-1">
          <input class="form-control outline" v-model="primary1Streams.numberOfStreamMultiGradeTeaching" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="primary2Streams.numberOfStreamMultiGradeTeaching" type="number" aria-label="male">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="primary3Streams.numberOfStreamMultiGradeTeaching" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="primary4Streams.numberOfStreamMultiGradeTeaching" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="primary5Streams.numberOfStreamMultiGradeTeaching" type="number" aria-label="female">
        </div>
        <div class="col me-1">
          <input class="form-control outline" v-model="primary6Streams.numberOfStreamMultiGradeTeaching" type="number" aria-label="female">
        </div>
      </div>
    </section>
    <div class="d-flex flex-column align-items-center justify-content-center">
      <button
          class="custom-btn font-tiny fw-bold"
          @click="submitForm"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "EnrolmentByStreams",
  setup() {
    const primary1Streams = {
      numberOfStream: "",
      numberOfStreamMultiGradeTeaching: "",
      schoolClass: "PRIMARY1"
    }
    const primary2Streams = {
      numberOfStream: "",
      numberOfStreamMultiGradeTeaching: "",
      schoolClass: "PRIMARY2"
    }
    const primary3Streams = {
      numberOfStream: "",
      numberOfStreamMultiGradeTeaching: "",
      schoolClass: "PRIMARY3"
    }
    const primary4Streams = {
      numberOfStream: "",
      numberOfStreamMultiGradeTeaching: "",
      schoolClass: "PRIMARY4"
    }
    const primary5Streams = {
      numberOfStream: "",
      numberOfStreamMultiGradeTeaching: "",
      schoolClass: "PRIMARY5"
    }
    const primary6Streams = {
      numberOfStream: "",
      numberOfStreamMultiGradeTeaching: "",
      schoolClass: "PRIMARY6"
    }

    return {
      primary1Streams,
      primary2Streams,
      primary3Streams,
      primary4Streams,
      primary5Streams,
      primary6Streams
    }
  },
  computed: {
    ownSchool() {
      return this.$store.state.school.ownSchool
    },
    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitEnrolmentByStream']),

    async submitForm() {
      const body = {
        requestBody: [
          this.primary1Streams, this.primary2Streams, this.primary3Streams,
          this.primary4Streams, this.primary5Streams, this.primary6Streams
        ],
        school: this.ownSchool.id
      }

      await this.submitEnrolmentByStream(body)
          .then(() => {
            this.$notification.open({
              type: this.updateMessage.status,
              message: this.message,
            })
            if (this.responseCode === 201) {
              this.$emit('submissionDone')
            }
          })
    }
  }
}
</script>

<style scoped>

</style>