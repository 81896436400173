<template>
  <div class="bg-white radius-full py-4 px-5 my-4">
    <p class="font-sm fw-bold mb-3">School's Health facility (by school level) in current academic class.</p>
    <div class="col-12 mt-3 mb-4">
      <label class="form-label font-sm fw-normal">
        Please select school level
      </label>
      <select class="form-select" required aria-label="Default select example" @change="levelChanged($event)" v-model="schoolLevel">
        <option value="">Select level</option>
        <option value="PRE_PRIMARY">Pre-Primary</option>
        <option value="PRIMARY">Primary</option>
        <option value="JUNIOR">Junior</option>
        <option value="SENIOR">Senior</option>
      </select>
    </div>
  </div>

  <div class="col-12 bg-white radius-full py-4 px-5 my-4">
    <label class="form-label font-sm fw-normal">
      What health facility does this school have?
    </label>
    <div class="col-12">
      <input
          type="text"
          class="form-control"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          :value="formattedHealthFac"
          :disabled="moeUser === 'ROLE_MOE_ADMIN'"
      />
      <div
          class="dropdown-menu bg-accent shadow-sm w-100"
          aria-labelledby="featuresDropdown"
      >
        <div class="d-flex flex-column w-100">
          <div class="pe-2">
            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  v-model="healthFac"
                  name="healthFacility"
                  value="HEALTH_CLINIC"
                  id="health-clinic"
              />
              <label class="form-check-label" for="health-clinic">
                Health clinic
              </label>
            </div>
          </div>
          <div class="pe-2">
            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  v-model="healthFac"
                  name="healthFacility"
                  value="FIRST_AID_KIT"
                  id="first-aid-kit"
              />
              <label class="form-check-label" for="first-aid-kit">
                First aid kit
              </label>
            </div>
          </div>
          <div class="pe-2">
            <div class="form-check">
              <input
                  class="form-check-input"
                  type="radio"
                  v-model="healthFac"
                  name="healthFacility"
                  value="NO_HEALTH_FACILITY"
                  id="no-health-facility"
              />
              <label class="form-check-label" for="no-health-facility">
                No health facility
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <br/>
    <div v-if="moeUser !== 'ROLE_MOE_ADMIN'" class="d-flex flex-column align-items-center justify-content-center mt-4">
      <button
          class="facilities-btn custom-btn font-tiny fw-bold"
          @click="submitSharedFacilities"
          :disabled="isFetched"
      >
        {{ isFetched || showTitle == false ? 'Update' : 'Save' }}
      </button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import {ref, watch} from "vue";

export default {
  name: "HealthFacilities",
  emits: ['reload'],
  props:['showTitle'],
  setup() {
    const store = useStore()
    const healthFacilityInfo = computed(() => store.state.questionnaireService.facilityHealth ? store.state.questionnaireService.facilityHealth[0] : store.state.questionnaireService.facilityHealth).value
    const healthFac = ref('')
    const formattedHealthFac = ref('')
    const isFetched = ref(false)
    const moeUser = computed(() => store.state.auth.user.role)
    let schoolLevel = '', healthFacility = ''

    if (healthFacilityInfo !== null) {
      ({schoolLevel, healthFacility} = healthFacilityInfo)
      healthFac.value = healthFacility
      formattedHealthFac.value = _.capitalize(healthFac.value).replaceAll("_", " ")
      isFetched.value = true
    }

    watch(healthFac, () => {
      formattedHealthFac.value = _.capitalize(healthFac.value).replaceAll("_", " ")
    })

    return {
      moeUser,
      schoolLevel: schoolLevel,
      healthFac,
      formattedHealthFac,
      isFetched
    }
  },
  computed: {
    schoolInfo() { return this.$store.state.school.ownSchool },
    responseCode() { return this.$store.state.questionnaireService.responseCode },
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['submitHealthFacility', 'fetchFacilityHealth', 'updateFacilityHealth']),
    ...mapMutations(['setFacilityHealth', 'setQAMessage']),

    levelChanged(event) {
      const requestObject = {
        id: this.schoolInfo.id ? this.schoolInfo.id : this.$store.state.school.ownSchool.id,
        level: event.target.value
      }
      this.fetchFacilityHealth(requestObject)
        .then(() => {
          this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        })
          if (this.responseCode === 200) {
            this.$emit('reload')
          }
        })
    },

    async submitSharedFacilities() {
      if (this.schoolLevel === '') {
        this.setQAMessage("Please select school level!")
      } else {
        const requestBody = {
          id: this.schoolInfo.id,
          body: {
            healthFacility: this.healthFac,
            schoolLevel: this.schoolLevel
          }
        }
        if (this.isFetched) {
          
          const requestBodyToupdate = {
          id: this.$store.state.questionnaireService.facilityHealth.id,
            body: {
              healthFacility: this.healthFac,
              schoolLevel: this.schoolLevel
            }
          }
          await this.updateFacilityHealth(requestBodyToupdate)
            .then(() => {
              this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        })
              if (this.responseCode === 204) {
                this.$emit('reload')
                this.setFacilityHealth(null)
              }
            })
        } else {
          await this.submitHealthFacility(requestBody)
              .then(() => {
                this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        })
                if (this.responseCode === 201) {
                  this.$emit('reload')
                }
              })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>