<template>
  <div class="d-flex flex-row-reverse bg-accent pt-4">
    <school-dashboard-sidebar
      :manageSchool="screen.schoolManagement"
      :dashboard="screen.dashboardSetting"
      @showDbSetting="showDashboardSetting"
      @showSchoolManagement="showSchoolManagement"
    />

    <div class="main-content col-md-9 px-5 align-self-end">
      <dashboard-header
        :hasBack="hasBack"
        @goBack="handleGoBack"
        @userProfile="showProfile"
        @editUser="editSchoolProfile"
        @accountSetting="showAccountSetting"
        @dashboardSetting="showDashboardSetting"
      />

      <school-management
        v-if="screen.schoolManagement"
        @createSchool="showCreateSchool"
        @viewSchools="showSchoolsTable"
      />

      <school-dashboard-settings v-if="screen.dashboardSetting" />

      <create-school
        v-if="screen.createSchool"
        :edit="screen.edit"
        :editSchool="selectedSchool"
      />

      <schools-table
        v-if="screen.schoolTable"
        @addNewSchool="showCreateSchool"
        @schoolDraft="showSchoolDraftTable"
        @showSchoolProfile="showSchoolProfile"
        @editSchoolInfo="editSchoolProfile"
        @showClassrooms="showSchoolClassrooms"
        @showSchoolStaffTable="showSchoolStaffTable"
        @showLearnerInfo="showSchoolLearnerInfo"
        @showQualifications="showTeachersQualification"
      />

      <school-information
        v-if="screen.schoolInfo"
        :school="selectedSchool"
        @editSchoolInfo="editSchoolProfile"
      />

      <staff-table
        v-if="screen.showStaffTable"
        :school-id="schoolId"
        @showStaffProfile="showStaffInfo"
      />

      <classrooms-table
        v-if="screen.showClassroomsTable"
        :id="selectedSchoolId"
      />

      <teachers-qualification-table
        v-if="screen.showQualificationsTable"
        :id="selectedSchoolId"
      />

      <staff-profile
        v-if="screen.viewStaffProfile"
        :staff-info="selectedStaffInfo"
      />
    </div>

    <!-- <transition name="modal">
      <modal v-if="hasMessage" @close="closeModal">
        <template v-slot:body>
          <p>{{ message }}</p>
        </template>
      </modal>
    </transition> -->
  </div>
</template>

<script>
import SchoolDashboardSidebar from "@/components/dashboardPage/school/dashboard/SchoolDashboardSidebar.vue";
import SchoolManagement from "@/components/dashboardPage/school/dashboard/SchoolManagement.vue";
import SchoolDashboardSettings from "@/components/dashboardPage/school/dashboard/SchoolDashboardSettings.vue";
import SchoolsTable from "@/components/dashboardPage/school/dashboard/SchoolsTable.vue";
import CreateSchool from "@/components/dashboardPage/school/schoolCreation/CreateSchool.vue";
import DashboardHeader from "@/components/dashboardPage/DashboardHeader";
import SchoolInformation from "@/components/dashboardPage/school/schoolCreation/SchoolInformation.vue";
import dashboardMixinVue from "../../helpers/mixins/dashboardMixin.vue";
import { resetAll } from "@/helpers/utils/helper_functions";
import StaffTable from "@/components/dashboardPage/user/StaffTable";
import StaffProfile from "@/components/dashboardPage/school/ASCDashboard/StaffProfile";
import { mapMutations } from "vuex";
import Modal from "@/helpers/utils/Modal";
import ClassroomsTable from "@/components/dashboardPage/school/dashboard/ClassroomsTable";
import TeachersQualificationTable from "@/components/dashboardPage/school/dashboard/TeachersQualificationTable";

export default {
  name: "SchoolDashboard",
  mixins: [dashboardMixinVue],
  components: {
    TeachersQualificationTable,
    ClassroomsTable,
    Modal,
    StaffProfile,
    StaffTable,
    SchoolDashboardSidebar,
    SchoolManagement,
    SchoolDashboardSettings,
    DashboardHeader,
    CreateSchool,
    SchoolsTable,
    SchoolInformation,
  },
  data() {
    return {
      hasBack: true,
      selectedSchool: null,
      schoolId: null,
      selectedStaffInfo: null,
      selectedSchoolId: null,
      lastScreen: null,
      currentScreen: null,
      screen: {
        profile: false,
        schoolInfo: false,
        accountSetting: false,
        dashboardSetting: false,
        schoolManagement: true,
        createSchool: false,
        schoolTable: false,
        edit: false,
        draftTable: false,
        showStaffTable: false,
        showStaffInfo: false,
        viewStaffProfile: false,
        showClassroomsTable: false,
        showQualificationsTable: false,
      },
    };
  },

  computed: {
    pagedSchools() {
      return this.$store.state.school.pagedSchools.content;
    },
    message() {
      return this.$store.state.school.schoolMessage;
    },

    hasMessage() {
      return this.message !== null;
    },
  },

  methods: {
    ...mapMutations(["setSchoolMessage"]),

    showProfile() {
      this.setLastScreen();
      resetAll(this.screen);
      this.screen.profile = true;
      this.currentScreen = "profile";
    },
    showAccountSetting() {
      this.setLastScreen();
      resetAll(this.screen);
      this.screen.accountSetting = true;
      this.currentScreen = "accountSetting";
    },
    showDashboardSetting() {
      this.setLastScreen();
      resetAll(this.screen);
      this.screen.dashboardSetting = true;
      this.currentScreen = "dashboardSetting";
    },
    showSchoolManagement() {
      this.setLastScreen();
      resetAll(this.screen);
      this.screen.schoolManagement = true;
      this.currentScreen = "schoolManagement";
    },
    showCreateSchool() {
      this.setLastScreen();
      resetAll(this.screen);
      this.screen.createSchool = true;
      this.currentScreen = "createSchool";
    },
    showSchoolsTable() {
      this.setLastScreen();
      resetAll(this.screen);
      this.screen.schoolTable = true;
      this.currentScreen = "schoolTable";
    },
    editSchoolProfile(schoolCode) {
      if (schoolCode) {
        this.selectedSchool = this.pagedSchools.find(
          (school) => school.schoolCode === schoolCode
        );
      }
      this.showCreateSchool();
      this.screen.edit = true;
    },
    showSchoolDraftTable() {
      this.setLastScreen();
      resetAll(this.screen);
      this.screen.draftTable = true;
      this.currentScreen = "draftTable";
    },
    showSchoolProfile(schoolCode) {
      this.setLastScreen();
      if (schoolCode) {
        this.selectedSchool = this.pagedSchools.find(
          (school) => school.schoolCode === schoolCode
        );
      }
      resetAll(this.screen);
      this.screen.schoolInfo = true;
      this.currentScreen = "schoolInfo";
    },
    showSchoolStaffTable(schoolId) {
      this.setLastScreen();
      this.schoolId = schoolId;
      resetAll(this.screen);
      this.screen.showStaffTable = true;
      this.currentScreen = "showStaffTable";
    },

    showStaffInfo(staffInfo) {
      this.setLastScreen();
      this.selectedStaffInfo = staffInfo;
      resetAll(this.screen);
      this.screen.viewStaffProfile = true;
      this.currentScreen = "viewStaffProfile";
    },

    showSchoolLearnerInfo() {
      console.log("Show school learner info.");
    },

    showSchoolClassrooms(schoolId) {
      this.setLastScreen();
      this.selectedSchoolId = schoolId;
      resetAll(this.screen);
      this.screen.showClassroomsTable = true;
      this.currentScreen = "showClassroomsTable";
    },

    showTeachersQualification(schoolId) {
      this.setLastScreen();
      this.selectedSchoolId = schoolId;
      resetAll(this.screen);
      this.screen.showQualificationsTable = true;
      this.currentScreen = "showQualificationsTable";
    },

    setLastScreen() {
      let screens = Object.entries(this.screen);
      screens.forEach((key) => {
        if (key[1] === true) {
          this.lastScreen = key[0];
        }
      });
    },

    handleGoBack() {
      if (this.lastScreen === null || this.currentScreen === this.lastScreen) {
        this.goBack();
      } else {
        resetAll(this.screen);
        this.screen[this.lastScreen] = true;
        this.currentScreen = this.lastScreen;
      }
    },

    closeModal() {
      this.setSchoolMessage(null);
    },
  },
};
</script>
<style lang="scss">
@import "../../style/variable";

.active {
  color: $blue-dm;
}
</style>
