<template>
  <div class="pt-5">
    <a-collapse v-model:activeKey="activeKey" :bordered="false" accordion>
      <template #expandIcon="{ isActive }">
        <caret-right-outlined :rotate="isActive ? 90 : 0" />
      </template>

      <a-collapse-panel :style="customStyle" key="1" header="School Identification">
        <section class="mb-4 mx-3">
          <school-identification v-if="moeUser !== 'ROLE_MOE_ADMIN'" :operation="operation" />

          <div v-else>
             <button  v-if="showSchoolProfile" @click="showSchoolProfile = false" class="custom-btn font-tiny fw-bold">
              View table
            </button>
              <schools-table
                v-if="!showSchoolProfile"
                :title="false"
                @showSchoolProfile="handleShowSchoolProfile"
               />
               <school-information
                v-if="showSchoolProfile"  
                :school="selectedSchool"
                :title="false"
                 />
          </div>
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="2" header="School Characteristics">
        <section class="mb-4 mx-3">
          <school-characteristics :operation="operation" />
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="3" header="Enrolment">
        <section class="mb-4 mx-3">
          <div v-if="moeUser == 'ROLE_MOE_ADMIN'">
            <schools-table
              v-if="!showEnrollmentTable"
              :title="false"
              @showSchoolProfile="handleShowEnrollmentTable"
              
              />
          <enrolments-table 
            v-if="showEnrollmentTable"
            :schoolId="selectedSchoolId"
            @viewEnrolmentInfo="enrolmentInfo" />
          </div>
          <enrolments-table 
            v-else
            :schoolId="selectedSchoolId"
            @viewEnrolmentInfo="enrolmentInfo" />
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="4" header="Staff">
        <section class="mb-4 mx-3">
          <div v-if="moeUser == 'ROLE_MOE_ADMIN'">
            <schools-table
              v-if="!showSchoolStaffsTable"
              :title="false"
              @showSchoolProfile="handleShowShoolStaffsTable"
              />
              <staff-table
          v-if="showSchoolStaffsTable && staffOperation !='view'"
          :showTitle="false"
          :schoolId="selectedSchoolId"
          @showStaffProfile="showProfile"
          @editStaff="editStaff "
          />
          <staff-profile v-if="staffOperation == 'view'" :staff-info="staffInfo" :showTitle="false" />
          <div v-if="staffOperation == 'view'"  class="d-flex align-items-center justify-content-center">
            <button class="custom-btn font-sm fw-bold" @click="staffOperation = '', showSchoolStaffsTable = false" >Show schools table</button>
          </div>
          </div>
          <div v-else>
            <staff-table
              v-if="!staffOperation"
              :showTitle="false"
              :schoolId="selectedSchoolId"
              @showStaffProfile="showProfile"
              @editStaff="editStaff "             
          />
          <SchoolStaff v-if="staffOperation == 'edit'" :staffData="staffData" operation="edit" :showTitle="false" />
          <staff-profile v-if="staffOperation == 'view' && !isSupervisor" :staff-info="staffInfo" :showTitle="false" />
          <div v-if="staffOperation == 'edit' || staffOperation == 'view'"  class="d-flex align-items-center justify-content-center">
            <button class="custom-btn font-sm fw-bold" @click="staffOperation = ''" >Show staffs table</button>
          </div>
          </div>
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="5" header="Classrooms">
        <section class="mb-4 mx-3">
          <classroom-view-and-update />
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="6" header="Facilities">
        <section class="mb-4 mx-3">
          <!-- <facility-table @viewFacilityInfo="facilityInfo"/>  -->
          <div v-if="moeUser == 'ROLE_MOE_ADMIN'">
            <schools-table
              v-if="!showSchoolFacilities"
              :title="false"
              @showSchoolProfile="handleShowShoolSFacilities"
              />
              <facilities
              v-if="showSchoolFacilities"
              @saveFacilities="saveFacilities"
              :showTitle="false"
              :selectedSchoolInfo="selectedSchoolFacilities"
            />
          </div>
          <div v-else>
            <facilities
            @saveFacilities="saveFacilities"
            :showTitle="false"
            
            />
          </div>
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="7" header="Number of students by subject">
        <section class="mb-4 mx-3">
          <div v-if="moeUser == 'ROLE_MOE_ADMIN'">
             <schools-table
              v-if="!showStudentBySubject"
              :title="false"
              @showSchoolProfile="handleShowStudentbySubjectTable"
              />
              <student-by-subject-table v-if="showStudentBySubject"  :schoolId="selectedSchoolId"/>
          </div>
          <div v-else>
            <student-by-subject-table />
          </div>
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="8" header="Student/teaher book">
        <section class="mb-4 mx-3">

          <div v-if="moeUser == 'ROLE_MOE_ADMIN'">
            <schools-table
              v-if="!showStudentTeachersBook"
              :title="false"
              @showSchoolProfile="handleShowshowStudentTeachersBookTable"
              />
            <student-teacher-book v-if="showStudentTeachersBook" :schoolId="selectedSchoolId" />
          </div>

          <div v-else>
            <student-teacher-book />
          </div>
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="9" header="Teachers qualification">
        <section class="mb-4 mx-3">
          <!-- <teacher-qualification-view-and-update /> -->
        <div v-if="moeUser == 'ROLE_MOE_ADMIN'">
          <schools-table
              v-if="!showTeachersQualification"
              :title="false"
              @showSchoolProfile="handleShowTeachersQualification"
              />

            <teachers-qualification
            v-if="showTeachersQualification"
            :key="genKey"
            @reload="genKey++"
            @continueNextSection="saveQualification"
            :schoolId="selectedSchoolId"
          />
        </div>

        <div v-else>
           <teachers-qualification
            :key="genKey"
            @reload="genKey++"
            @continueNextSection="saveQualification"
          />
        </div>
        </section>
      </a-collapse-panel>
      <a-collapse-panel :style="customStyle" key="10" header="Family life">
        <section class="mb-4 mx-3">
          <family-life-view-and-update />
        </section>
      </a-collapse-panel>
    </a-collapse>

    <transition name="modal">
      <modal v-if="hasMessage" @close="closeModal">
        <template v-slot:body>
          <p>{{ message }}</p>
        </template>
      </modal>
    </transition>
  </div>
</template>


<script>
import SchoolsTable from "@/components/dashboardPage/school/dashboard/SchoolsTable.vue";
import CreateSchool from "@/components/dashboardPage/school/schoolCreation/CreateSchool.vue";
import SchoolInformation from "@/components/dashboardPage/school/schoolCreation/SchoolInformation.vue";

import { Collapse, CollapsePanel} from "ant-design-vue";
import { CaretRightOutlined } from '@ant-design/icons-vue';
import SchoolIdentification from "@/components/dashboardPage/school/ASCDashboard/questionnaire/SchoolIdentification";
import EnrolmentsTable from "@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/EnrolmentsTable";
import FacilityTable from "@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/FacilityTable";
import ClassroomViewAndUpdate from "@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/ClassroomViewAndUpdate";
import TeacherQualificationViewAndUpdate from "@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/TeacherQualificationViewAndUpdate";
import TeachersQualification from '@/components/dashboardPage/school/ASCDashboard/questionnaire/TeachersQualification';

import FamilyLifeViewAndUpdate from "@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/FamilyLifeViewAndUpdate";
import Modal from "@/helpers/utils/Modal";
import SchoolCharacteristics from "@/components/dashboardPage/school/ASCDashboard/questionnaire/SchoolCharacteristics"
import {onMounted, ref, computed} from "vue"
import {useStore} from 'vuex'
import NewSchoolEnrollment from "@/components/dashboardPage/school/ASCDashboard/questionnaire/NewSchoolEnrollment"
import StaffTable from "@/components/dashboardPage/user/StaffTable";
import SchoolStaff from "@/components/dashboardPage/school/ASCDashboard/questionnaire/SchoolStaff";
import StaffProfile from "@/components/dashboardPage/school/ASCDashboard/StaffProfile";
import StudentBySubjectTable from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/studentBySubjectSection/StudentBySubjectTable.vue';
import StudentTeacherBook from '@/components/dashboardPage/school/ASCDashboard/ascViewAndUpdate/studentTeacherBookSection/StudentTeacherBookTable.vue';
import Facilities from "@/components/dashboardPage/school/ASCDashboard/questionnaire/Facilities";
import Roles from "@/authorities/roles";

// import { useStore } from 'vuex';

export default {
  name: "SubmissionReview",
  props: ['institutionId'],
  components: {
    'a-collapse': Collapse,
    'a-collapse-panel': CollapsePanel,
    CaretRightOutlined,
    SchoolIdentification,
    SchoolCharacteristics,
    Modal,
    EnrolmentsTable,
    NewSchoolEnrollment,
    StaffTable,
    SchoolStaff,
    StaffProfile,
    StudentBySubjectTable,
    FacilityTable,
    StudentTeacherBook,
    Facilities,
    ClassroomViewAndUpdate,
    TeacherQualificationViewAndUpdate,
    FamilyLifeViewAndUpdate,
    SchoolsTable,
    CreateSchool,
    SchoolInformation,
    TeachersQualification

  },
  setup(props, { emit }) {
    const store = useStore()
    const operation = ref('view')
    const staffOperation = ref('')
    const staffData =ref('')
    const staffInfo = ref('')
    const userMe = computed(() => store.getters.loginUser).value;
    const isSupervisor = userMe.role === Roles.supervisor
    const activeKey = ref(0)
    const hasMessage = ref(false)
    const customStyle = 'background: #ffffff; border-radius: 4px; margin-bottom: 10px; border: none;'
    const showStudentBySubject = ref(false)
    const enrolmentInfo = (key) => { emit('enrolmentInfo', key) }
    const facilityInfo = (key) => { emit('facilityInfo', key) } 
    const moeUser = computed(() => store.state.auth.user.role)
    const showSchoolProfile = ref(false)
    const selectedSchool = ref('')
    const pagedSchools = computed(() => store.state.school.pagedSchools.content)
    const showEnrollmentTable = ref(false)
    const selectedSchoolId = ref('')
    const showSchoolStaffsTable = ref(false)
    const selectedSchoolInfo = ref('')
    const showSchoolFacilities = ref(false)
    const selectedSchoolFacilities = ref('')
    const showStudentTeachersBook = ref(false)
    const genKey = ref(0)
    const showTeachersQualification = ref(false)
    // onMounted(() => {      
    //     console.log(store.state.school.ownSchool.id)
    //   store.dispatch('viewSubmissionForSchoolWithId', props.institutionId)
    // })
    function showProfile(staff){
      staffOperation.value = 'view'
      staffInfo.value = staff
      console.log(staff);
    }
    function editStaff(data){
      staffOperation.value = 'edit'
      staffData.value = data
    }
    function saveFacilities() {
      this.setFacilitiesDone(true)
      this.setPreviousScreen('facilities')
      this.showSubject()
    }
    function handleShowSchoolProfile({schoolCode}) {
      console.log('Showing school profile')
      selectedSchool.value = pagedSchools.value.find(school => school.schoolCode === schoolCode)
      console.log(selectedSchool.value)
      showSchoolProfile.value = true
    }

    function handleShowEnrollmentTable({schoolId}) {
      showEnrollmentTable.value = true
      selectedSchoolId.value = schoolId
    }
    
    function handleShowShoolStaffsTable({schoolId}) {
      showSchoolStaffsTable.value = true
      selectedSchoolId.value = schoolId
    }

    function handleShowShoolSFacilities(schoolInfo) {
      selectedSchoolFacilities.value = schoolInfo
      showSchoolFacilities.value = true
      console.log(selectedSchoolInfo.value)
    }

    function handleShowStudentbySubjectTable({id}) {
      selectedSchoolId.value = id
      showStudentBySubject.value = true
    }

    function handleShowshowStudentTeachersBookTable({id}) {
      selectedSchoolId.value = id
      showStudentTeachersBook.value = true
    }

    function handleShowTeachersQualification({id}) {
      console.log(id)
      selectedSchoolId.value = id
      showTeachersQualification.value = true
    }
    return {
      showTeachersQualification,
      handleShowTeachersQualification,
      genKey,
      showStudentTeachersBook,
      handleShowshowStudentTeachersBookTable,
      handleShowStudentbySubjectTable,
      showStudentBySubject,
      selectedSchoolFacilities,
      showSchoolFacilities,
      handleShowShoolSFacilities,
      handleShowShoolStaffsTable,
      showSchoolStaffsTable,
      selectedSchoolId,
      showEnrollmentTable,
      handleShowEnrollmentTable,
      handleShowSchoolProfile,
      showSchoolProfile,
      selectedSchool,
      showSchoolProfile,
      activeKey,
      customStyle,
      hasMessage,
      operation,
      enrolmentInfo,
      facilityInfo,
      staffOperation,
      showProfile,
      editStaff,
      staffData,
      staffInfo,
      isSupervisor,
      saveFacilities,
      moeUser
    }
  },
  mounted(){

  },
  computed:{
    ownSchoolId() {
      return this.$store.state.school.ownSchool.id
    }
  }

}
</script>