<template>
  <div>
		<p class="font-md fw-bold mt-2 mb-4">Teachers Qualification</p>
    <div class="bg-white radius-full py-4 px-5 my-4">
      <p class="font-sm fw-bold mb-3">Teachers qualification (by level and class) in current academic class.</p>
      <div class="col-12 mt-3 mb-4">
        <label class="form-label font-sm fw-normal">
          Please select school level
        </label>
        <select class="form-select" required aria-label="Default select example" @change="levelChanged($event)" v-model="schoolLevel">
          <option value="">Select level</option>
          <option value="PRE_PRIMARY">Pre-Primary</option>
          <option value="PRIMARY">Primary</option>
          <option value="JUNIOR">Junior</option>
          <option value="SENIOR">Senior</option>
        </select>
      </div>
    </div>
		<div class="d-flex flex-column bg-white radius-full pt-3 px-5 my-4">
			<section class="mb-5">
				<span class="font-sm fw-medium">Level of teaching qualifications in <strong>{{ schoolLevel }}</strong></span>
				<div class="d-flex mb-2">
					<div class="col-6"></div>
					<span class="col-2 text-center font-sm fw-normal">Male</span>
					<span class="col-2 text-center font-sm fw-normal">Female</span>
				</div>
				<div class="d-flex mb-3">
					<div class="col-6"><span class="font-sm fw-normal">Below SSCE</span></div>
					<div class="col-2 me-3">
						<input class="form-control outline" v-model="belowSSCEMale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="male">
					</div>
					<div class="col-2">
						<input class="form-control outline" v-model="belowSSCEFemale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="female">
					</div>
				</div>
				<div class="d-flex mb-3">
					<div class="col-6"><span class="font-sm fw-normal">SSCE/WASC</span></div>
					<div class="col-2 me-3">
						<input class="form-control outline" v-model="ssceWascMale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="male">
					</div>
					<div class="col-2">
						<input class="form-control outline" v-model="ssceWascFemale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="female">
					</div>
				</div>
				<div class="d-flex mb-3">
					<div class="col-6"><span class="font-sm fw-normal">OND/Diploma</span></div>
					<div class="col-2 me-3">
						<input class="form-control outline" v-model="ondDiplomaMale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="male">
					</div>
					<div class="col-2">
						<input class="form-control outline" v-model="ondDiplomaFemale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="female">
					</div>
				</div>
				<div class="d-flex mb-3">
					<div class="col-6"><span class="font-sm fw-normal">NCE</span></div>
					<div class="col-2 me-3">
						<input class="form-control outline" v-model="nceMale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="male">
					</div>
					<div class="col-2">
						<input class="form-control outline" v-model="nceFemale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="female">
					</div>
				</div>
				<div class="d-flex mb-3">
					<div class="col-6"><span class="font-sm fw-normal">PGDE</span></div>
					<div class="col-2 me-3">
						<input class="form-control outline" v-model="pgdeMale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="male">
					</div>
					<div class="col-2">
						<input class="form-control outline" v-model="pgdeFemale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="female">
					</div>
				</div>
				<div class="d-flex mb-3">
					<div class="col-6"><span class="font-sm fw-normal">B.Ed</span></div>
					<div class="col-2 me-3">
						<input class="form-control outline" v-model="boeMale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="male">
					</div>
					<div class="col-2">
						<input class="form-control outline" v-model="boeFemale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="female">
					</div>
				</div>
				<div class="d-flex mb-3">
					<div class="col-6"><span class="font-sm fw-normal">M.Ed</span></div>
					<div class="col-2 me-3">
						<input class="form-control outline" v-model="moeMale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="male">
					</div>
					<div class="col-2">
						<input class="form-control outline" v-model="moeFemale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="female">
					</div>
				</div>
				<div class="d-flex mb-3">
					<div class="col-6"><span class="font-sm fw-normal">Grade II</span></div>
					<div class="col-2 me-3">
						<input class="form-control outline" v-model="gradeTwoMale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="male">
					</div>
					<div class="col-2">
						<input class="form-control outline" v-model="gradeTwoFemale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="female">
					</div>
				</div>
				<div class="d-flex mb-3">
					<div class="col-6"><span class="font-sm fw-normal">B.A (Ed)</span></div>
					<div class="col-2 me-3">
						<input class="form-control outline" v-model="baEDMale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="male">
					</div>
					<div class="col-2">
						<input class="form-control outline" v-model="baEDFemale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="female">
					</div>
				</div>
				<div class="d-flex mb-3">
					<div class="col-6"><span class="font-sm fw-normal">B.Sc./HND</span></div>
					<div class="col-2 me-3">
						<input class="form-control outline" v-model="degreeHNDGradMale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="male">
					</div>
					<div class="col-2">
						<input class="form-control outline" v-model="degreeHNDGradFemale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="female">
					</div>
				</div>
				<div class="d-flex mb-3">
					<div class="col-6"><span class="font-sm fw-normal">B.Sc.(Ed)</span></div>
					<div class="col-2 me-3">
						<input class="form-control outline" v-model="bscEduMale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="male">
					</div>
					<div class="col-2">
						<input class="form-control outline" v-model="bscEduFemale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="female">
					</div>
				</div>
				<div class="d-flex">
					<div class="col-6"><span class="font-sm fw-normal">Other degree/graduate</span></div>
					<div class="col-2 me-3">
						<input class="form-control outline" v-model="otherGraduateMale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="male">
					</div>
					<div class="col-2">
						<input class="form-control outline" v-model="otherGraduateFemale.value" type="number" :disabled="moeUser === 'ROLE_MOE_ADMIN'" aria-label="female">
					</div>
				</div>
			</section>

      <div v-if="moeUser !=='ROLE_MOE_ADMIN'" class="d-flex flex-column align-items-center justify-content-center mb-4">
        <button
            v-if="isFetched"
            class="custom-btn font-tiny fw-bold"
            @click="submitForm"
        >
          Update
        </button>
        <button
            v-else
            class="custom-btn font-tiny fw-bold"
            @click="submitForm"
        >
          Save
        </button>
      </div>
		</div>

    <div v-if="moeUser !=='ROLE_MOE_ADMIN'" class="d-flex flex-column align-items-center justify-content-center">
      <span
          @click="$emit('continueNextSection')"
          class="font-sm dm-color my-3 fw-medium pointer-no-bg"
      >
        <strong>Continue</strong>
      </span>
    </div>

    <transition name="modal">
      <modal v-if="hasMessage" @close="closeModal">
        <template v-slot:body>
          <p>{{ message }}</p>
        </template>
      </modal>
    </transition>
	</div>
</template>

<script>
import {mapActions, mapMutations, useStore} from "vuex";
import {computed} from "@vue/runtime-core";
import Modal from "@/helpers/utils/Modal";
import {ref} from "vue";

export default {
	name: "TeachersQualification",
  components: {Modal},
  emits: ['reload'],
  props:['schoolId'],
  setup(props) {
    const store = useStore()
    const schoolInfo = computed(() => props.schoolId ? props.schoolId : store.state.school.ownSchool.id).value
    let schoolLevel = ref('')
    let isFetched = ref(false)
    const fetchedQualifications = computed(() => store.state.questionnaireService.teacherQualifications).value
    let belowSSCEMaleObject = {}, belowSSCEFemaleObject = {}, ssceWascMaleObject = {}, ssceWascFemaleObject = {},
        ondDiplomaMaleObject = {}, ondDiplomaFemaleObject = {}, nceMaleObject = {}, nceFemaleObject = {},
        pgdeMaleObject = {}, pgdeFemaleObject = {}, boeMaleObject = {}, boeFemaleObject = {}, moeMaleObject = {}, moeFemaleObject = {},
        gradeTwoMaleObject = {}, gradeTwoFemaleObject = {}, baEDMaleObject = {}, baEDFemaleObject = {},
        bscEduMaleObject = {}, bscEduFemaleObject = {}, otherGraduateMaleObject = {}, otherGraduateFemaleObject = {},
        degreeHNDGradMaleObject = {}, degreeHNDGradFemaleObject = {}


    if (fetchedQualifications !== null && Object.entries(fetchedQualifications).length > 0) {

      schoolLevel.value = fetchedQualifications[0].schoolLevelOption
      isFetched.value = true

      belowSSCEMaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'BELOW_SSCE' && qualification.gender === 'MALE')
      belowSSCEFemaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'BELOW_SSCE' && qualification.gender === 'FEMALE')

      ssceWascMaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'SSCE_WASC' && qualification.gender === 'MALE')
      ssceWascFemaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'SSCE_WASC' && qualification.gender === 'FEMALE')

      ondDiplomaMaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'OND_DIPLOMA' && qualification.gender === 'MALE')
      ondDiplomaFemaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'OND_DIPLOMA' && qualification.gender === 'FEMALE')

      nceMaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'NCE' && qualification.gender === 'MALE')
      nceFemaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'NCE' && qualification.gender === 'FEMALE')

      pgdeMaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'PGDE' && qualification.gender === 'MALE')
      pgdeFemaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'PGDE' && qualification.gender === 'FEMALE')

      boeMaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'BACHELOR_OF_EDUCATION' && qualification.gender === 'MALE')
      boeFemaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'BACHELOR_OF_EDUCATION' && qualification.gender === 'FEMALE')

      moeMaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'MASTER_OF_EDUCATION' && qualification.gender === 'MALE')
      moeFemaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'MASTER_OF_EDUCATION' && qualification.gender === 'FEMALE')

      gradeTwoMaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'GRADE_II' && qualification.gender === 'MALE')
      gradeTwoFemaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'GRADE_II' && qualification.gender === 'FEMALE')

      baEDMaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'BA_ED' && qualification.gender === 'MALE')
      baEDFemaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'BA_ED' && qualification.gender === 'FEMALE')

      bscEduMaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'BSC_EDU' && qualification.gender === 'MALE')
      bscEduFemaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'BSC_EDU' && qualification.gender === 'FEMALE')

      otherGraduateMaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'OTHER_DEGREE_GRADUATE' && qualification.gender === 'MALE')
      otherGraduateFemaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'OTHER_DEGREE_GRADUATE' && qualification.gender === 'FEMALE')

      degreeHNDGradMaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'DEGREE_OR_HND_OR_GRADUATE' && qualification.gender === 'MALE')
      degreeHNDGradFemaleObject = fetchedQualifications.find(qualification => qualification.highestQualification === 'DEGREE_OR_HND_OR_GRADUATE' && qualification.gender === 'FEMALE')
    }

    const belowSSCEMale = {
      gender: 'MALE',
      highestQualification: 'BELOW_SSCE',
      value: belowSSCEMaleObject.value,
      id: belowSSCEMaleObject.id
    }
    const belowSSCEFemale = {
      gender: 'FEMALE',
      highestQualification: 'BELOW_SSCE',
      value: belowSSCEFemaleObject.value,
      id: belowSSCEFemaleObject.id
    }
    const ssceWascMale = {
      gender: 'MALE',
      highestQualification: 'SSCE_WASC',
      value: ssceWascMaleObject.value,
      id: ssceWascMaleObject.id
    }
    const ssceWascFemale = {
      gender: 'FEMALE',
      highestQualification: 'SSCE_WASC',
      value: ssceWascFemaleObject.value,
      id: ssceWascFemaleObject.id
    }
    const ondDiplomaMale = {
      gender: 'MALE',
      highestQualification: 'OND_DIPLOMA',
      value: ondDiplomaMaleObject.value,
      id: ondDiplomaMaleObject.id
    }
    const ondDiplomaFemale = {
      gender: 'FEMALE',
      highestQualification: 'OND_DIPLOMA',
      value: ondDiplomaFemaleObject.value,
      id: ondDiplomaFemaleObject.id
    }
    const nceMale = {
      gender: 'MALE',
      highestQualification: 'NCE',
      value: nceMaleObject.value,
      id: nceMaleObject.id
    }
    const nceFemale = {
      gender: 'FEMALE',
      highestQualification: 'NCE',
      value: nceFemaleObject.value,
      id: nceFemaleObject.id
    }
    const pgdeMale = {
      gender: 'MALE',
      highestQualification: 'PGDE',
      value: pgdeMaleObject.value,
      id: pgdeMaleObject.id
    }
    const pgdeFemale = {
      gender: 'FEMALE',
      highestQualification: 'PGDE',
      value: pgdeFemaleObject.value,
      id: pgdeFemaleObject.id
    }
    const boeMale = {
      gender: 'MALE',
      highestQualification: 'BACHELOR_OF_EDUCATION',
      value: boeMaleObject.value,
      id: boeMaleObject.id
    }
    const boeFemale = {
      gender: 'FEMALE',
      highestQualification: 'BACHELOR_OF_EDUCATION',
      value: boeFemaleObject.value,
      id: boeFemaleObject.id
    }
    const moeMale = {
      gender: 'MALE',
      highestQualification: 'MASTER_OF_EDUCATION',
      value: moeMaleObject.value,
      id: moeMaleObject.id
    }
    const moeFemale = {
      gender: 'FEMALE',
      highestQualification: 'MASTER_OF_EDUCATION',
      value: moeFemaleObject.value,
      id: moeFemaleObject.id
    }
    const gradeTwoMale = {
      gender: 'MALE',
      highestQualification: 'GRADE_II',
      value: gradeTwoMaleObject.value,
      id: gradeTwoMaleObject.id
    }
    const gradeTwoFemale = {
      gender: 'FEMALE',
      highestQualification: 'GRADE_II',
      value: gradeTwoFemaleObject.value,
      id: gradeTwoFemaleObject.id
    }
    const baEDMale = {
      gender: 'MALE',
      highestQualification: 'BA_ED',
      value: baEDMaleObject.value,
      id: baEDMaleObject.id
    }
    const baEDFemale = {
      gender: 'FEMALE',
      highestQualification: 'BA_ED',
      value: baEDFemaleObject.value,
      id: baEDFemaleObject.id
    }
    const bscEduMale = {
      gender: 'MALE',
      highestQualification: 'BSC_EDU',
      value: bscEduMaleObject.value,
      id: bscEduMaleObject.id
    }
    const bscEduFemale = {
      gender: 'FEMALE',
      highestQualification: 'BSC_EDU',
      value: bscEduFemaleObject.value,
      id: bscEduFemaleObject.id
    }
    const otherGraduateMale = {
      gender: 'MALE',
      highestQualification: 'OTHER_DEGREE_GRADUATE',
      value: otherGraduateMaleObject.value,
      id: otherGraduateMaleObject.id
    }
    const otherGraduateFemale = {
      gender: 'FEMALE',
      highestQualification: 'OTHER_DEGREE_GRADUATE',
      value: otherGraduateFemaleObject.value,
      id: otherGraduateFemaleObject.id
    }
    const degreeHNDGradMale = {
      gender: 'MALE',
      highestQualification: 'DEGREE_OR_HND_OR_GRADUATE',
      value: degreeHNDGradMaleObject.value,
      id: degreeHNDGradMaleObject.id
    }
    const degreeHNDGradFemale = {
      gender: 'FEMALE',
      highestQualification: 'DEGREE_OR_HND_OR_GRADUATE',
      value: degreeHNDGradFemaleObject.value,
      id: degreeHNDGradFemaleObject.id
    }

    const moeUser = computed(() => store.state.auth.user.role)

    return {
      moeUser,
      belowSSCEMale,
      belowSSCEFemale,
      ssceWascMale,
      ssceWascFemale,
      ondDiplomaMale,
      ondDiplomaFemale,
      nceMale,
      nceFemale,
      pgdeMale,
      pgdeFemale,
      boeMale,
      boeFemale,
      moeMale,
      moeFemale,
      gradeTwoMale,
      gradeTwoFemale,
      baEDMale,
      baEDFemale,
      bscEduMale,
      bscEduFemale,
      otherGraduateMale,
      otherGraduateFemale,
      degreeHNDGradMale,
      degreeHNDGradFemale,

      schoolInfo,
      schoolLevel,
      isFetched
    }
  },
  computed: {
    updateMessage() {
      return this.$store.state.questionnaireService.updateMessage
    },
    message() {
      if (this.updateMessage !== null) {
        return this.updateMessage.message
      }
      return ''
    },
    hasMessage() {
      return this.message !== null
    },

    responseCode() {
      return this.$store.state.questionnaireService.responseCode
    },

    teacherQualification() {
      return this.$store.state.questionnaireService.teacherQualifications
    }
  },
  methods: {
    ...mapActions(['submitTeachersQualification', 'fetchSchoolTeachersQualificationUnpaged', 'updateTeachersQualification']),
    ...mapMutations(['setQAMessage', 'setResponseCode', 'setTeacherQualifications']),

    async submitForm() {
      const formData = [
          this.belowSSCEMale,
          this.belowSSCEFemale,
          this.ssceWascMale,
          this.ssceWascFemale,
          this.ondDiplomaMale,
          this.ondDiplomaFemale,
          this.nceMale,
          this.nceFemale,
          this.pgdeMale,
          this.pgdeFemale,
          this.boeMale,
          this.boeFemale,
          this.moeMale,
          this.moeFemale,
          this.gradeTwoMale,
          this.gradeTwoFemale,
          this.baEDMale,
          this.baEDFemale,
          this.bscEduMale,
          this.bscEduFemale,
          this.otherGraduateMale,
          this.otherGraduateFemale,
          this.degreeHNDGradMale,
          this.degreeHNDGradFemale
      ]

      if (this.schoolLevel === "") {
        this.setQAMessage("Please select the school level you wish to submit teachers information for.")
      } else {
        formData.map(item => item['schoolLevelOption'] = this.schoolLevel)
        if (this.isFetched) {
          await this.updateTeachersQualification(formData)
            .then(() => {
              this.$notification.open({
                type: this.updateMessage.status,
                message: this.message,
              })
              if (this.responseCode === 204) {
                this.setTeacherQualifications(null)
                this.$emit('reload')
              }
            })
        } else {
          formData.map(item => item['id'] = undefined)
          const requestObject = {
            id: this.schoolInfo,
            body: formData
          }
          await this.submitTeachersQualification(requestObject)
            .then(() => {
              this.$notification.open({
                type: this.updateMessage.status,
                message: this.message,
              })
              if (this.responseCode === 201) {
                this.$emit('reload')
              }
            })
        }
      }
    },

    async levelChanged(event) {
      console.log(this.schoolInfo)
      await this.fetchSchoolTeachersQualificationUnpaged({id: this.schoolInfo, level: event.target.value})
      .then(() => {
        this.$notification.open({
          type: this.updateMessage.status,
          message: this.message,
        })
        if (this.responseCode === 200) {
          if (this.teacherQualification !== null && this.teacherQualification.length > 0) {
            this.$emit('reload')
          } else {
            this.setQAMessage("No record found for this level!")
          }
        }
      })
    },

    closeModal() {
      this.setQAMessage(null)
      this.setResponseCode(null)
    }
  }
}
</script>